import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "react-phone-input-2/lib/style.css";
import "../styles/Checkout.css";
import "../styles/Credit.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

const CURRENT_YEAR = new Date().getFullYear();
const CURRENT_MONTH = new Date().getMonth();
const MONTHS = {};
const YEARS = [CURRENT_YEAR];

for (let i = 1; i <= 12; i++) {
  MONTHS[i] = i.toString().length === 1 ? `0${i}` : i.toString();
  YEARS.push(YEARS[0] + i);
}

const Credit = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const [focused, setFocused] = useState(false);

  const [cardNumber, setCardNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [cardMonth, setCardMonth] = useState(0);
  const [cardYear, setCardYear] = useState(0);
  const [cardCvv, setCardCvv] = useState('');
  const [cardType, setCardType] = useState('visa');
  const [toggleMonth, setToggleMonth] = useState(true);
  const [toggleYear, setToggleYear] = useState(true);
  const [showCard, setShowCard] = useState(false);
  const [cardFlipped, setCardFlipped] = useState(false);

  const formatCardNumber = (value) => {
    let v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    let matches = v.match(/\d{4,16}/g);
    let match = (matches && matches[0]) || "";
    let parts = [];
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    if (parts.length) {
      return parts.join(" ");
    } else {
      return value;
    }
  };

  const getCardType = (number) => {
    let re = new RegExp("^4");
    if (number.match(re) != null) return "visa";
    re = new RegExp("^(34|37)");
    if (number.match(re) != null) return "amex";
    re = new RegExp("^5[1-5]");
    if (number.match(re) != null) return "mastercard";
    re = new RegExp("^6011");
    if (number.match(re) != null) return "discover";
    return "visa";
  };

  const handleChange = (event, type) => {
    let { value } = event.target;

    if (type === "cardNumber") {
      value = value.replace(/ /gi, "");
      if (isNaN(value)) return;
      const formattedValue = formatCardNumber(value);
      const cardType = getCardType(value);
      setCardNumber(formattedValue);
      setCardType(cardType);
    } else if (type === "cardName") {
      const regName = /^[a-zA-Z\s]*$/;
      if (regName.test(value)) {
        setCardName(value);
      }
    } else if (type === "cardMonth") {
      value = Number(value);
      setCardMonth(value);
      setToggleMonth(!toggleMonth);
    } else if (type === "cardYear") {
      value = Number(value);
      if (value === CURRENT_YEAR && cardMonth <= CURRENT_MONTH) {
        setCardMonth(0);
        setCardYear(value);
        setToggleYear(!toggleYear);
        setToggleMonth(!toggleMonth);
      } else {
        setCardYear(value);
        setToggleYear(!toggleYear);
      }
    } else if (type === "cardCvv") {
      setCardCvv(value);
    }
  };

  const profile = useSelector((state) => state.profile.profile.data);
  const cart = localStorage.getItem('cart');
  
  const cartProducts = JSON.parse(cart).map(product => ({
    code: product.code,
    count: product.count,
    color: product.color,
    size: product.size,
  }));

  const payData = localStorage.getItem('checkoutData');
  const checkoutData = JSON.parse(payData)

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      cardDetails: {
        cardNumber,
        cardName,
        cardMonth,
        cardYear,
        cardCvv,
      },
      profile,
      cartProducts,
      checkoutData
    };
    console.log(data)
    // dispatch(handlePay(data))
  }

  return (
    <div className="bg-[#F3F9FF] pb-10 relative px-24 max-lg:px-5 checkout">
      <div className="flex items-center pt-10 max-lg:pt-5">
        <ArrowLeftOutlined className="text-[#DE3033] mr-3" />
        <Link to="/checkout" className="shop text-[#DE3033]">
          BACK TO CHECKOUT
        </Link>
      </div>
      <div className="bg-[#F3F9FF] Credit">
        <div className="lg:mt-5">
          <div className="credit-card-form">
            <div className={`card ${cardFlipped ? 'flipped' : ''}`}>
              <div className="card-front">
                <div className={`card-type ${cardType}`}></div>
                <div className={`card-number`}>{cardNumber || '#### #### #### ####'}</div>
                <div className="grid grid-cols-2 max-lg:grid-cols-1 gap-6">
                  <div className="card-name">
                    <p className="text-[#ababab]">Card Holder</p>
                    <p className="namee">{cardName || 'FULL NAME'}</p>
                  </div>
                  <div className="card-expiration">
                    <p className="text-[#ababab]">Expires</p>
                    <p className="exp">{cardMonth && cardYear ? `${MONTHS[cardMonth]}/${cardYear.toString().slice(-2)}` : 'MM/YY'}</p>
                  </div>
                </div>
              </div>
              <div className="card-back">
                <div className={`card-type back_visa`}></div>
                <div className="card-cvv">
                  <p className="text-[#ababab]">CVV</p>
                  <p className="text-xl">{cardCvv}</p>
                </div>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Card Number</label>
                <input
                  type="text"
                  value={cardNumber}
                  placeholder="#### #### #### ####"
                  onChange={(e) => handleChange(e, 'cardNumber')}
                  onFocus={() => setShowCard(true)}
                  onBlur={() => setShowCard(false)}
                />
              </div>

              <div className="form-group">
                <label>Card Name</label>
                <input
                  type="text"
                  value={cardName}
                  placeholder="FULL NAME"
                  onChange={(e) => handleChange(e, 'cardName')}
                  onFocus={() => setShowCard(true)}
                  onBlur={() => setShowCard(false)}
                />
              </div>

              <div className="flex justify-between">
                <div className="form-group w-full">
                  <label>Expiration Date</label>
                  <div className="expiration-date">
                    <select
                      className="w-full mr-5"
                      value={cardMonth}
                      onChange={(e) => handleChange(e, 'cardMonth')}
                    >
                      <option value="">MM</option>
                      {Object.keys(MONTHS).map((month) => (
                        <option key={month} value={month}>
                          {MONTHS[month]}
                        </option>
                      ))}
                    </select>
                    <select
                      className="w-full mr-5"
                      value={cardYear}
                      onChange={(e) => handleChange(e, 'cardYear')}
                    >
                      <option value="">YY</option>
                      {YEARS.map((year) => (
                        <option key={year} value={year}>
                          {year.toString().slice(-2)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form-group">
                  <label>CVV</label>
                  <input
                    className="cvv"
                    type="text"
                    value={cardCvv}
                    onChange={(e) => handleChange(e, 'cardCvv')}
                    placeholder="###"
                    onFocus={() => setCardFlipped(true)}
                    onBlur={() => setCardFlipped(false)}
                  />
                </div>
              </div>

              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Credit;
