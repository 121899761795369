import React, { useEffect, useState } from "react";
import { Empty, Rate, Tabs, Button, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { rateProduct } from "../../redux/ratingSlice";
import { Link, Navigate, useNavigate } from "react-router-dom"; // Import useNavigate
import { logout } from "../../redux/authSlice";
import Orders from "./Orders";
import ProfileForm from "./ProfileForm";
import TitleProfile from "../../components/profileComp/TitleProfile";
import Translate from "./Translate";
import ResetPassword from "./ResetPassword";
import { LockOutlined, LogoutOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { fetchOrders } from "../../redux/orderSlice";

const Profile = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const orders = useSelector((state) => state.orders.orders);
  const ordersStatus = useSelector((state) => state.orders.status);
  const { status: ratingStatus, error: ratingError } = useSelector((state) => state.rating);

  const [tabPosition, setTabPosition] = useState("left");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemsToRate, setItemsToRate] = useState([]);
  const [ratings, setRatings] = useState({});
  const [comments, setComments] = useState({});

  const [hasRated, setHasRated] = useState(false);

  useEffect(() => {
    const ratedStatus = localStorage.getItem('hasRated');
    if (ratedStatus) {
      setHasRated(true);
    }
  }, []);

  useEffect(() => {
    if (ordersStatus === "idle") {
      dispatch(fetchOrders());
    }
  }, [ordersStatus, dispatch]);

  useEffect(() => {
    const handleResize = () => {
      setTabPosition(window.innerWidth <= 1280 ? "top" : "left");
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (ordersStatus === 'succeeded' && !hasRated) {
      const deliveredOrders = orders?.filter(order => order.status === 'delivered');
      const items = deliveredOrders?.flatMap(order => order.items.map(item => ({
        item_id: item.item_id,
        name_en: item.item?.name_en
      })));

      if (items?.length > 0) {
        setItemsToRate(items);
        setIsModalOpen(true); 
      }
    }
  }, [orders, ordersStatus, hasRated]);

  const handleRatingChange = (value, itemId) => {
    setRatings({ ...ratings, [itemId]: value });
  };

  const handleCommentChange = (event, itemId) => {
    setComments({ ...comments, [itemId]: event.target.value });
  };

  const handleRatingSubmit = () => {
    itemsToRate.forEach(item => {
      const { item_id } = item;
      const rating = ratings[item_id];
      const comment = comments[item_id];
      if (rating && comment) {
        dispatch(rateProduct({ item_id, rating, comment }));
      }
    });
    setIsModalOpen(false);
    setHasRated(true);
    localStorage.setItem('hasRated', 'true');
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/signup');
  };

  if (!isAuthenticated) {
    return <Empty description={<Link to="/login">Please login</Link>} className="py-20" />;
  }

  return (
    <div className={`profileStyle ${i18n.language === "en" ? 'lg:rounded-l-full profileStyle' : 'lg:rounded-r-full profileStyle-ar'} `}>
      <Modal
        title="We appreciate your feedback"
        footer={null}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        {itemsToRate.map(item => (
          <div key={item.item_id} className="mb-4">
            <h3>{item.name_en}</h3>
            <Rate
              allowHalf
              defaultValue={0}
              className="mt-4 flex mx-auto justify-center"
              onChange={(value) => handleRatingChange(value, item.item_id)}
              value={ratings[item.item_id] || 0}
            />
            <TextArea
              rows={4}
              placeholder="Comment"
              maxLength={200}
              className="mt-2"
              onChange={(e) => handleCommentChange(e, item.item_id)}
              value={comments[item.item_id] || ''}
            />
          </div>
        ))}
        <Button
          className="w-full mt-4 bg-black text-white text-lg py-5"
          onClick={handleRatingSubmit}
          loading={ratingStatus === 'loading'}
        >
          Submit
        </Button>
        {ratingStatus === 'succeeded' && <p>Rating submitted successfully!</p>}
        {ratingError && <p>Error: {ratingError}</p>}
      </Modal>

      <div className="px-14 max-lg:px-5 py-7">
        <TitleProfile name={user.firstname} />
        <div className="lg:mt-24">
          <Tabs
            defaultActiveKey="1"
            tabPosition={tabPosition}
            className={`${i18n.language === "en" ? 'custome_tap_profile' : 'custome_tap_profile-ar'}`}
          >
            <Tabs.TabPane
              tab={<span className="material-symbols-outlined py-2">supervisor_account</span>}
              key="1"
            >
              <ProfileForm />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<span className="material-symbols-outlined py-2">receipt</span>}
              key="2"
            >
              <div className={`${i18n.language === "ar" && "mr-6"}`}>
                <Orders />
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<span className="material-symbols-outlined py-2">translate</span>}
              key="3"
            >
              <Translate />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<LockOutlined className="text-xl py-2" />}
              key="4"
            >
              <ResetPassword />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<button onClick={handleLogout}><LogoutOutlined className="py-2 text-xl" /></button>}
              key="0"
            >
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Profile;
