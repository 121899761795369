import { configureStore } from "@reduxjs/toolkit";
import productReducer from "./productSlice";
import questionsReducer from "./faqSlice";
import catReducer from "./catSlice";
import subCatReducer from "./subCatSlice";
import contactsReducer from "./contactSlice";
import aboutReducer from "./aboutSlice";
import contactReducer from './contactFormSlice';
import countriesReducer from './countrySlice';
import citiesReducer from './citySlice';
import authReducer from './authSlice';
import taxReducer from './taxSlice';
import profileReducer from './profileSlice';
import orderReducer from './orderSlice';
import privacyReducer from './privacySlice';
import termsReducer from './termsSlice';
import ratingReducer from './ratingSlice';

export const store = configureStore({
  reducer: {
    products: productReducer,
    questions: questionsReducer,
    categories: catReducer,
    subcategories: subCatReducer,
    contacts: contactsReducer,
    aboutInfo: aboutReducer,
    contact: contactReducer,
    countries: countriesReducer,
    cities: citiesReducer,
    auth: authReducer,
    tax: taxReducer,
    profile: profileReducer,
    orders: orderReducer,
    privacy: privacyReducer,
    terms: termsReducer,
    rating: ratingReducer,
  },
});

export default store;
