import React, { useState } from "react";
import Navbar2 from '../components/Navbar2';
import MyProfile from '../components/profileComp/Profile';
import "../styles/profile.css";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const Profile = () => {

  const { t, i18n } = useTranslation();

  const { language } = i18n;

  const { isAuthenticated } = useSelector((state) => state.auth);

  if (!isAuthenticated) {
    return <Navigate to="/signup" />;
  }

  return (
    <div dir={language == 'en' ? 'ltr' : 'rtl'}>
      <Navbar2 />
      <MyProfile />
    </div>
  )
}

export default Profile