import React, { useEffect, useState } from "react";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { Button, Select, Form, Radio, Space, message } from "antd";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import "../styles/Checkout.css";
import { Link, useNavigate } from "react-router-dom";
import Inputs from "./Inputs";
import { useDispatch, useSelector } from "react-redux";
import { fetchCountries } from "../redux/countrySlice";
import { fetchCities } from "../redux/citySlice";
import { fetchTax } from "../redux/taxSlice";
import { createProfile } from "../redux/authSlice";
import { fetchProfile } from "../redux/profileSlice";
import ProfileInputs from "./ProfileInputs";
import { useTranslation } from "react-i18next";
import { postPayment } from "../redux/paymentSlice";

const CheckoutComp = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const { user, isLoading } = useSelector((state) => state.auth);
  console.log("🚀 ~ ProfileForm ~ user:", user);

  const profile = useSelector((state) => state.profile.profile.data);
  const profileStatus = useSelector((state) => state.profile.status);
  const error = useSelector((state) => state.profile.error);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [address, setAddress] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (profileStatus === "idle") {
      dispatch(fetchProfile());
    }
  }, [profileStatus, dispatch]);

  useEffect(() => {
    if (profile?.user?.firstname) {
      setFirstname(profile.user.firstname);
    }
    if (profile?.user?.lastname) {
      setLastname(profile.user.lastname);
    }
    if (profile?.user?.email) {
      setEmail(profile.user.email);
    }
    if (profile?.user?.phone) {
      setPhone(profile.user.phone);
    }
    if (profile?.user?.country_id) {
      setSelectedCountry(profile.user.country_id);
    }
    if (profile?.user?.city_id) {
      setSelectedCity(profile.user.city_id);
    }
    if (profile?.user?.address) {
      setAddress(profile.user.address);
    }
  }, [profile]);

  const [focused, setFocused] = useState(false);

  const countries = useSelector((state) => state.countries.countries);
  const countriesStatus = useSelector((state) => state.countries.status);
  const errorCountries = useSelector((state) => state.countries.error);

  const cities = useSelector((state) => state.cities.cities);
  const citiesStatus = useSelector((state) => state.cities.status);
  const errorCities = useSelector((state) => state.cities.error);

  const tax = useSelector((state) => state.tax.tax);
  const taxStatus = useSelector((state) => state.tax.status);
  // const error = useSelector((state) => state.tax.error);

  useEffect(() => {
    if (taxStatus === "idle") {
      dispatch(fetchTax());
    }
  }, [taxStatus, dispatch]);

  useEffect(() => {
    if (countriesStatus === "idle") {
      dispatch(fetchCountries());
    }
  }, [countriesStatus, dispatch]);

  useEffect(() => {
    if (selectedCountry) {
      dispatch(fetchCities(selectedCountry));
    }
  }, [selectedCountry, dispatch]);

  const cart = useSelector((state) => state.products.cart);

  const [quantities, setQuantities] = useState(
    cart.reduce((acc, product) => ({ ...acc, [product.id]: product.count }), {})
  );

  // const handleIncrease = (id) => {
  //   setQuantities((prevQuantities) => ({
  //     ...prevQuantities,
  //     [id]: prevQuantities[id] + 1,
  //   }));
  // };

  const handleIncrease = (id) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: Math.max(prevQuantities[id] + 1, 1),
    }));
  };

  const handleDecrease = (id) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: Math.max(prevQuantities[id] - 1, 1),
    }));
  };

  // const handleDecrease = (id) => {
  //   setQuantities((prevQuantities) => ({
  //     ...prevQuantities,
  //     [id]: Math.max(prevQuantities[id] - 1, 1),
  //   }));
  // };

  const totalPrice = cart.reduce(
    (total, product) => total + product.price * quantities[product.id],
    0
  );

  const shippingCost = cart.reduce(
    (total, product) => total + product.shipping_v,
    0
  );

  // Calculate the tax amount
  const taxAmount = totalPrice * (tax / 100);

  // Calculate the total cost including tax
  const totalCost = totalPrice + shippingCost + taxAmount;

  const handleCountrySelect = (value) => {
    setSelectedCountry(value);
    setSelectedCity(null);
  };

  const handleCitySelect = (value) => {
    setSelectedCity(value);
  };

  const [value, setValue] = useState(1);
  const onChange = (e) => {
    setValue(e.target.value);
  };

  // const profile = useSelector((state) => state.profile.profile.data);
  const cartStorage = localStorage.getItem("cart");

  const cartProducts = JSON.parse(cartStorage).map((product) => ({
    code: product.code,
    count: product.count,
    color: product.color,
    size: product.size,
  }));

  const payData = localStorage.getItem("checkoutData");
  const checkoutData = JSON.parse(payData);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const profileData = {
  //     firstname,
  //     lastname,
  //     email,
  //     address,
  //     phone,
  //     country_id: selectedCountry,
  //     city_id: selectedCity,
  //   };

  //   dispatch(createProfile(profileData))
  //     .then(() => {
  //       const checkoutData = {
  //         totalPrice,
  //         shippingCost,
  //         totalCost,
  //         tax,
  //         payType:
  //           value === 1
  //             ? "cash"
  //             : value === 2
  //             ? "PayPal"
  //             : value === 3
  //             ? "visa"
  //             : "Cash",
  //       };

  //       const data = {
  //         profile,
  //         cartProducts,
  //         checkoutData,
  //       };

  //       const tokenLocal = localStorage.getItem("token");

  //       dispatch(postPayment({ data, token: tokenLocal }))
  //         .then(() => {
  //           message.success(t("Payment successful"));
  //           if (value === 1) {
  //             navigate("/checkout");
  //           } else if (value === 2) {
  //             navigate("/checkout");
  //           } else if (value === 3) {
  //             navigate("/checkout");
  //           } else {
  //             navigate("/checkout");
  //           }
  //         })
  //         .catch((error) => {
  //           console.error("Payment Error:", error);
  //         });
  //     })
  //     .catch((error) => {
  //       console.error("Profile Creation Error:", error);
  //     });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const profileData = {
      firstname,
      lastname,
      email,
      address,
      phone,
      country_id: selectedCountry,
      city_id: selectedCity,
    };
  
    try {

      const createdProfile = await dispatch(createProfile(profileData)).unwrap();
  
      const checkoutData = {
        totalPrice,
        shippingCost,
        totalCost,
        tax,
        payType:
          value === 1
            ? "cash"
            : value === 2
            ? "PayPal"
            : value === 3
            ? "visa"
            : "Cash",
      };
  
      const data = {
        profile: createdProfile,
        cartProducts,
        checkoutData,
      };
  
      const tokenLocal = localStorage.getItem("token");
  
      await dispatch(postPayment({ data, token: tokenLocal })).unwrap();
  
      message.success(t("Payment successful"));
      navigate("/checkout");
    } catch (error) {
      console.error("Error:", error);
      message.error(t("Something went wrong. Please try again."));
    }
  };

  
  const selectedCountryName =
    language == "en"
      ? countries.find((country) => country.id === selectedCountry)?.name_en
      : countries.find((country) => country.id === selectedCountry)?.name;
  const selectedCityName =
    language == "en"
      ? cities.find((city) => city.id === selectedCity)?.name_en
      : cities.find((city) => city.id === selectedCity)?.name;

  return (
    <div className="bg-[#F3F9FF] relative px-24 max-lg:px-5 checkout">
      <div className="flex items-center pt-10 max-lg:pt-5">
        {language === "en" ? (
          <ArrowLeftOutlined className="text-[#DE3033] mr-3" />
        ) : (
          <ArrowRightOutlined className="text-[#DE3033] ml-3" />
        )}
        <Link to="/cart" className="shop text-[#DE3033]">
          {t("BACK_TO_CART")}
        </Link>
      </div>
      <div className="bg-[#F3F9FF] grid grid-cols-3 max-lg:grid-cols-1 gap-4">
        <form
          onSubmit={handleSubmit}
          className="lg:col-span-2 bg-white border-2 rounded-lg drop-shadow-md relative m-8 max-lg:m-0 max-lg:mt-4 p-10"
        >
          <div className="grid grid-cols-2 max-lg:grid-cols-1 gap-6 max-lg:gap-0">
            <ProfileInputs
              label={t("First name")}
              value={firstname}
              onChange={setFirstname}
            />
            <ProfileInputs
              label={t("Last name")}
              value={lastname}
              onChange={setLastname}
            />
          </div>

          <ProfileInputs
            label={t("Email address")}
            value={email}
            onChange={setEmail}
          />

          {/* <div>
            <Form.Item
              className={`${
                language == "en"
                  ? "floating-label-input"
                  : "floating-label-input-ar"
              } ${
                language == "en"
                  ? "react-tel-input selected-flag "
                  : "react-tel-input-ar selected-flag "
              } focused phone_number`}
              label={t("Phone number")}
            >
              <PhoneInput
                country={"eg"}
                value={phone}
                onChange={(phone) => setPhone(phone)}
                disableCountryCode={false}
                onlyCountries={["eg", "us", "gb"]}
                preferredCountries={["eg"]}
                placeholder={t("Enter phone number")}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                className={`bg-white p-2 ${
                  language == "en"
                    ? "floating-label-input"
                    : "floating-label-input-ar"
                } ${
                  language == "en"
                    ? "profileStyle react-tel-input form-control"
                    : "profileStyle-ar react-tel-input form-control"
                } ${
                  language == "en"
                    ? "react-tel-input selected-flag "
                    : "react-tel-input-ar selected-flag "
                } rounded placeholder:text-black placeholder:text-xl placeholder:font-normal placeholder:font-Arimo`}
              />
            </Form.Item>
          </div> */}

          <div className="relative -top-3">
            <label
              className={`${
                language === "en"
                  ? "floating-label-input"
                  : "floating-label-input floating-label-input-ar"
              } focused font-semibold relative top-2 right-2`}
            >
              {t("Phone number")}
            </label>

            <PhoneInput
              defaultCountry="eg"
              value={phone}
              onChange={(phone) => setPhone(phone)}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              className={`bg-[#F3F9FF] p-2 rounded placeholder:text-black placeholder:text-xl placeholder:font-normal placeholder:font-Arimo ${
                language === "en" ? "en_phone" : "ar_phone"
              }`}
            />
          </div>

          <div className="mt-5"></div>

          <div className="grid grid-cols-2 max-lg:grid-cols-1 gap-6 mb-6">
            <Select
              value={selectedCountryName}
              onChange={handleCountrySelect}
              className="rounded placeholder:text-black placeholder:text-xl placeholder:font-normal placeholder:font-Arimo"
              placeholder={t("COUNTRY")}
              options={countries.map((country) => ({
                label: language == "en" ? country.name_en : country.name,
                value: country.id,
              }))}
              suffixIcon={<CaretDownOutlined />}
            />
            <Select
              value={selectedCityName}
              onChange={handleCitySelect}
              className="rounded placeholder:text-black placeholder:text-xl placeholder:font-normal placeholder:font-Arimo"
              placeholder={t("City")}
              options={cities.map((city) => ({
                label: language == "en" ? city.name_en : city.name,
                value: city.id,
              }))}
              suffixIcon={<CaretDownOutlined />}
            />
          </div>

          <ProfileInputs
            label={t("Address")}
            value={address}
            onChange={setAddress}
          />

          <Button
            htmlType="submit"
            className="border-0 py-6 px-8 rounded-lg flex lg:ml-auto max-lg:w-full save_btn"
          >
            {isLoading ? t("loading") : t("SAVE_CONTINUE")}
          </Button>
        </form>
        <div className="relative max-lg:mb-6">
          <div className="lg:mt-14">
            <div className="w-96 max-lg:w-full border-2 rounded-lg drop-shadow-md bg-white">
              <div className="table w-full py-4 px-8 ">
                <div className="headingp text-2xl pb-4">
                  <h3>{t("ORDER SUMMARY")}</h3>
                </div>
                <hr className="text-gray-400" />
                <div className="headingps flex justify-between py-4">
                  <h3>
                    {cart.length} {t("ITEMS")}
                  </h3>
                  <h3>
                    {" "}
                    {totalPrice} {t("EGP")}
                  </h3>
                </div>
                <div className="headingps flex justify-between pb-4">
                  <h3>{t("DELIVERY COST")}</h3>
                  <h3>
                    {shippingCost} {t("EGP")}
                  </h3>
                </div>
                <div className="headingps flex justify-between pb-4">
                  <h3>{t("TAX")}</h3>
                  <h3>{tax} %</h3>
                </div>
                <hr className="text-gray-400" />
                <div className="headingps flex justify-between font-semibold py-4">
                  <h3>{t("TOTAL")}</h3>
                  <h3>
                    {totalCost.toFixed(2)} {t("EGP")}
                  </h3>
                </div>
                <div className="bg-[#000000BF] rounded-lg p-4 px-6 mt-1 text-xl">
                  <Radio.Group onChange={onChange} value={value}>
                    <Space direction="vertical">
                      <Radio className="text-white" value={1}>
                        {t("CASH")}
                      </Radio>
                      {/* <Radio className="text-white" value={2}>
                        PAYPAL
                      </Radio>
                      <Radio className="text-white" value={3}>
                        CREDIT CARD
                      </Radio> */}
                    </Space>
                  </Radio.Group>
                </div>
                <h6
                  className="text-center text-[#A1A1A1] mx-auto mt-4 mt-3"
                  style={{ fontSize: "10px" }}
                >
                  {t("BY_SUBMIT")}
                  <br />
                  <Link to="/" className="underline">
                    {t("PRIVACY_POLICY")}
                  </Link>{" "}
                  {t("AND")}{" "}
                  <Link to="/" className="underline">
                    {t("CONDITIONS_USE")}
                  </Link>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutComp;
