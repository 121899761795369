import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { publicAxios } from '../api';

export const createContact = createAsyncThunk(
    'contact/createContact',
    async (formData, { rejectWithValue }) => {
      try {
        const response = await publicAxios.post('/api/contact_create', formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        return response.data;
      } catch (error) {
        if (error.response) {
          console.error('Server Error:', error.response.data);
          return rejectWithValue(error.response.data);
        } else if (error.request) {
          console.error('No response:', error.request);
          return rejectWithValue(error.request);
        } else {
          console.error('Error:', error.message);
          return rejectWithValue(error.message);
        }
      }
    }
  );
  

const contactFormSlice = createSlice({
  name: 'contactForm',
  initialState: {
    loadingForm: false,
    errorForm: null,
    successForm: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createContact.pending, (state) => {
        state.loadingForm = true;
        state.errorForm = null;
        state.successForm = false;
      })
      .addCase(createContact.fulfilled, (state, action) => {
        state.loadingForm = false;
        state.successForm = true;
      })
      .addCase(createContact.rejected, (state, action) => {
        state.loadingForm = false;
        state.errorForm = action.payload;
      });
  },
});

export default contactFormSlice.reducer;

