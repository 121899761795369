import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { publicAxios } from '../api';

const loadFromStorage = (key) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : [];
};

const saveToStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const initialState = {
  products: [],
  bestItems: [],
  featuredItems: [],
  latestItems: [],
  realted_items: [],
  favorites: loadFromStorage('favorites'),
  cart: loadFromStorage('cart'),
  status: 'idle',
  error: null,
};


export const fetchProducts = createAsyncThunk('products/fetchProducts', async () => {
  const response = await publicAxios.get('/api/get_items');
  return response.data.data;
});


export const fetchBestItems = createAsyncThunk('products/fetchBestItems', async () => {
  const response = await publicAxios.get('/api/get_bestitems');
  return response.data.data;
});

export const fetchFeaturedItems = createAsyncThunk('products/fetchFeaturedItems', async () => {
  const response = await publicAxios.get('/api/get_feature_items');
  return response.data.data;
});

export const fetchLatestItems = createAsyncThunk('products/fetchLatestItems', async () => {
  const response = await publicAxios.get('/api/get_latestitems');
  return response.data.data;
});

export const fetchRealted_items = createAsyncThunk('products/fetchRealted_items', async (item_id) => {
  const response = await publicAxios.get(`/api/realted_items/${item_id}`);
  return response.data.data;
});

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    addFavorite: (state, action) => {
      const product = state.products.find((product) => product.id === action.payload);
      if (product && !state.favorites.some((fav) => fav.id === product.id)) {
        state.favorites.push(product);
        saveToStorage('favorites', state.favorites);
      }
    },
    removeFavorite: (state, action) => {
      state.favorites = state.favorites.filter((product) => product.id !== action.payload);
      saveToStorage('favorites', state.favorites);
    },
    addToCart: (state, action) => {
      const product = state.products.find((product) => product.id === action.payload);
      if (product) {
        state.cart.push({ ...product, count: 1 });
        saveToStorage('cart', state.cart);
      }
    },
    addToCartWithCount: (state, action) => {
      const { id, count, size, color } = action.payload;
      console.log("🚀 ~ action.payload:", action.payload);
      const existingProduct = state.cart.find(
        (item) => item.id === id && item.size === size && item.color === color
      );

      if (!existingProduct) {
        const product = state.products.find((product) => product.id === id);
        if (product) {
          state.cart.push({
            ...product,
            count: count,
            size: size,
            color: color,
          });
        }
      }
      saveToStorage('cart', state.cart);
    },
    updateCartQuantity: (state, action) => {
      const { id, size, color, count } = action.payload;
      const product = state.cart.find(
        (item) => item.id === id && item.size === size && item.color === color
      );
      if (product) {
        product.count = count;
        saveToStorage('cart', state.cart);
      }
    },
    checkCart: (state, action) => {
      const ay7aga = state.cart.find((item) => item.id === action.payload);
      return ay7aga ? true : false;
    },
    removeFromCart: (state, action) => {
      const { id, size, color } = action.payload;
      state.cart = state.cart.filter(
        (product) => !(product.id === id && product.size === size && product.color === color)
      );
      saveToStorage('cart', state.cart);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchBestItems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBestItems.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.bestItems = action.payload;
      })
      .addCase(fetchBestItems.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchFeaturedItems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFeaturedItems.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.featuredItems = action.payload;
      })
      .addCase(fetchFeaturedItems.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchLatestItems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLatestItems.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.latestItems = action.payload;
      })
      .addCase(fetchLatestItems.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(fetchRealted_items.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRealted_items.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.realted_items = action.payload;
      })
      .addCase(fetchRealted_items.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
  },
});

export const {
  addFavorite,
  removeFavorite,
  addToCart,
  addToCartWithCount,
  updateCartQuantity,
  checkCart,
  removeFromCart,
} = productSlice.actions;

export default productSlice.reducer;