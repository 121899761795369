import React, { useEffect } from "react";
import Category from "./Category";
import glasses from "../assets/glasses.png";
import jacket from "../assets/jacket.png";
import shoes from "../assets/shoses.png";
import bag from "../assets/bag.png";

import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../redux/catSlice';
import { Empty } from "antd";

const Categories = () => {

    const dispatch = useDispatch();
    const categories = useSelector((state) => state.categories.categories);
    const categoriesStatus = useSelector((state) => state.categories.status);
    const error = useSelector((state) => state.categories.error);

    useEffect(() => {
        if (categoriesStatus === 'idle') {
            dispatch(fetchCategories());
        }
    }, [categoriesStatus, dispatch]);

    const { t, i18n } = useTranslation();

    const { language } = i18n;

    return (
        <div className='bg-white px-24 max-lg:px-5'>
            <div className="flex flex-col jusitfy-center ">
                <div className="flex  items-center justify-between">
                    <span></span>
                    <h2 className="text-3xl xl:text-4xl font-[none] ml-9 items-center font-semibold leading-7 xl:leading-9 text-gray-800">
                        {t('CATEGORIES')}
                    </h2>
                    <Link to="/categories" ><h5 className="text-xl" >{t('ALL')}</h5></Link>
                </div>
                <div className="grid grid-cols-3 max-lg:grid-cols-1 md:gap-x-8 mt-5 max-lg:mt-0">
                    <div className="flex flex-col space-y-4 md:space-y-8 mt-4 md:mt-0 ">

                        {categories.slice(0, 2).map((category, index) => (
                            <Category
                                key={index}
                                link={`/products?category=${category.id}`}
                                image={`${process.env.REACT_APP_BASE_URL}${category.image}`}
                                alt={category.alt}
                                catName={language === "en" ? category.name_en : category.name_ar}
                                modelsNumber={category.items.length}
                            />
                        ))}

                    </div>

                    {categories.slice(2, 4).map((category, index) => (
                        <Category
                            key={index}
                            link={`/products?category=${category.id}`}
                            image={`${process.env.REACT_APP_BASE_URL}${category.image}`}
                            alt={category.alt}
                            catName={language === "en" ? category.name_en : category.name_ar}
                            modelsNumber={category.items.length}
                        />
                    ))}

                </div>
            </div>
        </div>
    )
}

export default Categories
