import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Form } from "antd";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { createFromIconfontCN } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import "../styles/Checkout.css";
import pin from "../assets/pin.png";
import sms from "../assets/sms.png";
import call from "../assets/call.png";
import contact from "../assets/contact.png";
import facebook2 from "../assets/facebook 2.png";
import linkedin from "../assets//linkedin .png";
import tiktok from "../assets/tiktok.png";
import whatsapp from "../assets/whatsapp.png";
import instagram from "../assets/instagram.png";
import Inputs from "./Inputs";
import { fetchContacts } from "../redux/contactSlice";
import { createContact } from "../redux/contactFormSlice";

const ContactComp = () => {
  const [focused, setFocused] = useState(false);
  const { loadingForm, errorForm } = useSelector((state) => state.contact);
  const [phone, setPhone] = useState("");
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    vision: "",
  });

  const IconFont = createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js",
  });

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.contacts.contacts);
  const contactsStatus = useSelector((state) => state.contacts.status);
  const error = useSelector((state) => state.contacts.error);

  useEffect(() => {
    if (contactsStatus === "idle") {
      dispatch(fetchContacts());
    }
  }, [contactsStatus, dispatch]);

  if (contactsStatus === "failed") {
    return <div>Error: {error}</div>;
  }

  const message = "Hello, I am interested in your services.";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    dispatch(createContact({ ...formData, phone }));
    console.log({ ...formData, phone });
  };

  return (
    <div dir={language === "ar" ? "rtl" : "ltr"}>
      <div className="grid grid-cols-3 max-lg:grid-cols-1 gap-0 checkout">
        <div className="contactInfo bg-[#E2E2E2] max-lg:h-[500px] relative px-10">
          <p className=" text-2xl font-semibold mt-8 mb-14">
            {" "}
            {t("CONTACT INFORMATION")}
          </p>
          <div className="info flex items-center mb-10 relative z-10">
            <img src={call} className="w-6" alt="call" />
            <span className="text-lg mx-3">{contacts?.phone?.value}</span>
          </div>
          <div className="info flex items-center mb-10 relative z-10">
            <img src={sms} className="w-6" alt="sms" />
            <span className="text-lg mx-3">{contacts?.email?.value}</span>
          </div>
          <div className="info flex items-center mb-10 relative z-10">
            <img src={pin} className="w-6 " alt="pin" />
            <span className="text-lg mx-3">{contacts?.location?.value}</span>
          </div>

          <div>
            <img
              width="230px"
              src={contact}
              className={`absolute bottom-0 ${
                language === "ar" ? "rotate-180 left-0" : "right-0 bottom-0"
              }`}
              alt="contact"
            />
          </div>

          <div
            className={`cont-icon bg-black absolute bottom-10 flex justify-center mx-auto items-center py-2 lg:ml-10 px-6 rounded-lg `}
          >
            <Link to={contacts?.facebook?.value} className="mx-2">
              <img src={facebook2} alt="facebook" />
            </Link>
            <Link to={contacts?.tiktok?.value} className="mx-2">
              <img src={tiktok} alt="tiktok" />
            </Link>
            <Link to={contacts?.linked?.value} className="mx-2">
              <img src={linkedin} alt="linkedin" />
            </Link>
            <Link to={contacts?.insta?.value} className="mx-2">
              <img src={instagram} alt="instagram" />
            </Link>
            <Link
              to={`https://wa.me/${
                contacts?.phone?.value
              }?text=${encodeURIComponent(message)}`}
              className="mx-2"
            >
              <img src={whatsapp} alt="whatsapp" />
            </Link>
          </div>
        </div>

        <div className="lg:col-span-2">
          <div className="bg-[#D9D9D921] grid grid-cols-2 max-lg:grid-cols-1">
            <div className="lg:col-span-2 relative m-8 max-lg:m-0 max-lg:mt-4 py-10 max-lg:py-5 px-24 max-lg:px-5">
              <Form onFinish={handleSubmit} autoComplete="off">
                <div className="grid grid-cols-2 max-lg:grid-cols-1 gap-6 max-lg:gap-0">
                  <Inputs
                    label={t("First name")}
                    className="bg-[#F3F9FF]"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    required={true}
                  />
                  <Inputs
                    label={t("Last name")}
                    className="bg-[#F3F9FF]"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    required={false}
                  />
                </div>

                <Inputs
                  label={t("Email address")}
                  className="bg-[#F3F9FF]"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required={true}
                />
                {/* <Form.Item
                  className={`${language === "en" ? 'floating-label-input' : 'floating-label-input floating-label-input-ar'} focused phone_number`}
                  label={t('Phone number')}
                >
                  <PhoneInput
                    country={"eg"}
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    disableCountryCode={false}
                    onlyCountries={["eg", "us", "gb"]}
                    preferredCountries={["eg"]}
                    placeholder={t('Enter phone number')}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    className="bg-[#F3F9FF] p-2 rounded placeholder:text-black placeholder:text-xl placeholder:font-normal placeholder:font-Arimo"
                  />
                </Form.Item> */}

                <div className="relative -top-3">
                  <label
                    className={`${
                      language === "en"
                        ? "floating-label-input"
                        : "floating-label-input floating-label-input-ar"
                    } focused font-semibold relative top-2 right-2`}
                  >
                    {t("Phone number")}
                  </label>

                  <PhoneInput
                    defaultCountry="eg"
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    className={`bg-[#F3F9FF] p-2 rounded placeholder:text-black placeholder:text-xl placeholder:font-normal placeholder:font-Arimo ${
                      language === "en" ? "en_phone" : "ar_phone"
                    }`}
                  />
                </div>

                <div className="mt-5"></div>

                <Inputs
                  label={t("Address")}
                  className="bg-[#F3F9FF]"
                  name="vision"
                  value={formData.vision}
                  onChange={handleChange}
                  required={false}
                />
                <Button
                  htmlType="submit"
                  className={`border-0 py-6 px-8 rounded-lg lg:ml-auto max-lg:w-full lg:flex lg:justify-end save_btn ${
                    language === "ar" ? "flex flex-row-reverse" : "ltr"
                  }`}
                  disabled={loadingForm}
                >
                  {t("SEND")}
                </Button>
                {errorForm && <p>{JSON.stringify(errorForm)}</p>}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactComp;
