import React from 'react';
import ProductOrders from './ProductOrders';
import fdgdgdg from "../../assets/fdgdgdg.png";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const OrderItem = ({ order }) => {
    const { t, i18n } = useTranslation();
    const { language } = i18n;

    const favorites = useSelector(state => state.products.favorites);

    const isFav = (id) => {
        return favorites.some(item => item.id === id);
    };

    return (
        <div className='grid grid-cols-3 max-lg:grid-cols-1 lg:gap-4'>
            <div className='col-span-2'>
                <div className='bg-[#E6F7FF] rounded-lg px-5 py-3'>
                    <h3 className='text-base font-semibold text-start'>{t('Order')} {order.code}</h3>
                    <div className="headingps flex justify-between">
                        <h3>{order.items.length} {t('Items')}</h3>
                        <h3 className='font-semibold'>{order.total} {t('EGP')} </h3>
                    </div>
                </div>
                <div className='bg-white rounded-lg mt-5'>
                    <div className='grid grid-cols-3 max-xl:grid-cols-1 gap-4 px-5 pt-5'>
                        {order.items.map((item, idx) => (
                            <ProductOrders
                                key={item.id}
                                id={item.id}
                                image={`${process.env.REACT_APP_BASE_URL}${item?.item?.image}`}
                                name={language == "en" ? item?.item?.name_en : item?.item?.name_ar}
                                desc={language === "en" ? item?.item?.desc_en : item?.item?.desc_ar}
                                link={item?.item?.id}
                                price={item?.item?.price}
                                rate={item?.item?.rate || "N/A"}
                                offer={item?.item?.discountvalue > 0}
                                discount={item?.item?.discountvalue}
                                fav={isFav(item?.item?.id)}
                            />
                        ))}
                    </div>
                    <div className='border border-[#d9d9da] rounded-xl py-3 px-6'>
                        <div className='w-3/5 max-lg:w-full'>
                            <h3 className='text-lg'><span className='font-semibold'>{t('Address')}:</span> {order.location}</h3>
                            <div className='text-lg mt-3'>
                                <p className='font-semibold'>{t('Phone')}:</p>
                                <p>{order.phone}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-2">
                <div className="table px-8 py-7 w-full max-lg:px-5 bg-white rounded-lg lg:mt-20 text-lg max-lg:w-full max-lg:mt-8">
                    <div className="headingp mb-3">
                        <h3 className="text-2xl max-lg:text-xl font-semibold">
                            {t("ORDER SUMMARY")}
                        </h3>
                    </div>
                    <hr className="text-[#cfcfcf] border border-[#cfcfcf]" />
                    <div className="headingps flex justify-between py-2 mt-4">

                        <h3>{order.items.length} {t("items")}</h3>
                        <h3>{order.pre_total} {t("EGP")}</h3>
                    </div>
                    <div className="headingps flex justify-between py-2">
                        <h3>{t("DELIVERY COST")}</h3>
                        <h3>{order.total_shipping_v} {t("EGP")}</h3>
                    </div>
                    {/* <div className="headingps flex justify-between py-2 mb-4">
                        <h3>{t("TAX")}</h3>
                        <h3>{order.tax} {t("EGP")}</h3>
                    </div> */}
                    <hr className="text-[#cfcfcf] border border-[#cfcfcf] my-3" />
                    <div className="headingps flex justify-between py-2 font-semibold">
                        <h3>{t("TOTAL")}</h3>
                        <h3>{order.total} {t("EGP")}</h3>
                    </div>
                    <h3 className="text-sm font-semibold mt-1">
                        {t("PAYMENT WAS MADE VIA CASH")}
                    </h3>
                </div>
            </div>
        </div>
    );
};

export default OrderItem;
