import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { publicAxios } from '../api';

const initialState = {
  aboutInfo: [],
  status: 'idle',
  error: null,
};

export const fetchAboutInfo = createAsyncThunk('aboutInfo/fetchAboutInfo', async () => {
  const response = await publicAxios.get('/api/get_about_us');
  return response.data.data;
});

const aboutSlice = createSlice({
  name: 'aboutInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAboutInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAboutInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.aboutInfo = action.payload;
      })
      .addCase(fetchAboutInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default aboutSlice.reducer;