import React, { useState } from "react";
import { Form, Input } from 'antd';
import { useTranslation } from "react-i18next";

const Inputs = ({ label, name, value, onChange, required }) => {
    const [focused, setFocused] = useState(false);
    const { t, i18n } = useTranslation();
    const { language } = i18n;

    return (
        <Form.Item
            className={`${language === "en" ? 'floating-label-input' : 'floating-label-input floating-label-input-ar'} ${focused || value ? 'focused' : ''}`}
            label={focused || value ? label : ''}
        >
            <Input
                placeholder={label}
                value={value}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onChange={onChange}
                name={name}
                className="bg-[#F3F9FF] w-full p-3 font-semibold border-none shadow-none rounded placeholder:text-black placeholder:text-xl placeholder:font-normal placeholder:font-Arimo"
                required={required}
            />
        </Form.Item>
    );
};

export default Inputs;
