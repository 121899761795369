import React, { useEffect } from 'react';
import look from "../assets/look.png";
import ProductLatest from './ProductLatest';
import { Empty, Spin, Tabs } from 'antd';
import "../styles/LatestArrevels.css";
import { useDispatch, useSelector } from 'react-redux';
import { fetchBestItems, fetchFeaturedItems, fetchLatestItems, fetchProducts } from '../redux/productSlice';
import { useTranslation } from 'react-i18next';

const onChange = (key) => {
    console.log(key);
};

const LatestArrevels = () => {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.products.products);
    const bestItems = useSelector((state) => state.products.bestItems || []);
    const featuredItems = useSelector((state) => state.products.featuredItems || []);
    const latestItems = useSelector((state) => state.products.latestItems || []);
    const productStatus = useSelector((state) => state.products.status);
    const error = useSelector((state) => state.products.error);
    const favorites = useSelector(state => state.products.favorites);

    const { t, i18n } = useTranslation();
    const { language } = i18n;

    const isFav = (id) => {
        return favorites.some((item) => item.id === id);
    };

    useEffect(() => {
        if (productStatus === 'idle') {
            dispatch(fetchProducts());
            dispatch(fetchBestItems());
            dispatch(fetchFeaturedItems());
            dispatch(fetchLatestItems());
        }
    }, [productStatus, dispatch]);

    const topTwoBestItems = bestItems.slice(0, 4);
    const topTwoFeaturedItems = featuredItems.slice(0, 4);
    const topTwoLatestItems = latestItems.slice(0, 4);

    return (
        <div className={`grid grid-cols-3 max-xl:grid-cols-1 flex gap-0 max-lg:px-5 LatestArrevels ${language === "ar" && 'LatestArrevels_ar'}`}>
            <img src={look} className="h-full pl-0" />

            <div className="col-span-2" style={{ backgroundColor: "#F8F9FB" }}>
                <h2 className='flex items-center justify-center text-center text-[#828B93] mt-7 text-3xl max-lg:text-2xl'
                    style={{ fontWeight: "500", fontFamily: "none" }}
                >
                    <span className={`w-8 h-0.5 bg-black ${language === "en" ? "mr-3" : "ml-3"}`}></span>
                    {t('LATEST_ARRIVALS')}
                    <span className={`w-8 h-0.5 bg-black ${language === "en" ? "ml-3" : "mr-3"}`}></span>
                </h2>
                <h4 className='uppercase'
                    style={{ fontWeight: "700", fontSize: "10px", fontFamily: "none", color: "#828B938A", textAlign: "center" }}
                >
                    {t('newest_trends_from_top_brands')}
                </h4>

                <div className="justify-items-center">
                    <div className="lg: items-center py-4">
                        <Tabs defaultActiveKey="1" items={[
                            {
                                key: '1',
                                label: <>{language === "en" ? 'BESTSELLER' : 'الاكثر مبيعا'}</>,
                                children: <>
                                    {productStatus === 'loading' ? (
                                        <div className="text-center my-44"><Spin size="large" /></div>
                                    ) : productStatus === 'succeeded' && bestItems.length > 0 ? (
                                        <div className={`grid grid-cols-4 max-xl:grid-cols-1 gap-4 px-4`}>
                                            {topTwoBestItems.map((itemdata) => {
                                                if (!itemdata || !itemdata.item) return null; // Guard clause
                                                const { item } = itemdata;
                                                return (
                                                    <ProductLatest
                                                        key={item.id}
                                                        id={item.id}
                                                        image={`${process.env.REACT_APP_BASE_URL}${item.image}`}
                                                        name={language === "en" ? item.name_en : item.name_ar}
                                                        desc={language === "en" ? item.desc_en : item.desc_ar}
                                                        material={language === "en" ? item?.material?.name_en : item?.material?.name_ar}
                                                        link={item.id}
                                                        price={item.price}
                                                        sizes={item.sizes.map(size => size.size[0].size_name)}
                                                        active_color={item.color}
                                                        colors={item.colors.map(color => color.color)}
                                                        offer={item.discountvalue > 0}
                                                        discount={item.discountvalue}
                                                        rate={item.rate}
                                                        fav={isFav(item.id)}
                                                    />
                                                );
                                            })}
                                        </div>
                                    ) : productStatus === 'failed' ? (
                                        <div>{error}</div>
                                    ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="NO BEST SELLER" className='mt-24'/>}
                                </>
                            },
                            {
                                key: '2',
                                label: <>{language === "en" ? 'FEATUREDPRODUCTS' : 'منتجات مميزة'}</>,
                                children: <>
                                    {productStatus === 'loading' ? (
                                        <div className="text-center my-44"><Spin size="large" /></div>
                                    ) : productStatus === 'succeeded' && featuredItems.length > 0 ? (
                                        <div className={`grid grid-cols-4 max-xl:grid-cols-1 gap-4 px-4`}>
                                            {topTwoFeaturedItems.map((itemdata) => {
                                                if (!itemdata || !itemdata.itemdata) return null; // Guard clause
                                                const { itemdata: item } = itemdata;
                                                return (
                                                    <ProductLatest
                                                        key={item.id}
                                                        id={item.id}
                                                        image={`${process.env.REACT_APP_BASE_URL}${item.image}`}
                                                        name={language === "en" ? item.name_en : item.name_ar}
                                                        desc={language === "en" ? item.desc_en : item.desc_ar}
                                                        material={language === "en" ? item?.material?.name_en : item?.material?.name_ar}
                                                        link={item.id}
                                                        price={item.price}
                                                        sizes={item.sizes.map(size => size.size[0].size_name)}
                                                        active_color={item.color}
                                                        colors={item.colors.map(color => color.color)}
                                                        offer={item.discountvalue > 0}
                                                        discount={item.discountvalue}
                                                        rate={item.rate}
                                                        fav={isFav(item.id)}
                                                    />
                                                );
                                            })}
                                        </div>
                                    ) : productStatus === 'failed' ? (
                                        <div>{error}</div>
                                    ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="NO FEATURED PRODUCTS" className='mt-24'/>}
                                </>
                            },
                            {
                                key: '3',
                                label: <>{language === "en" ? 'LATESTPRODUCTS' : 'أحدث المنتجات'}</>,
                                children: <>
                                    {productStatus === 'loading' ? (
                                        <div className="text-center my-44"><Spin size="large" /></div>
                                    ) : productStatus === 'succeeded' && latestItems.length > 0 ? (
                                        <div className={`grid grid-cols-4 max-xl:grid-cols-1 gap-4 px-4`}>
                                            {topTwoLatestItems.map((item) => {
                                                if (!item || !item.id) return null; // Guard clause
                                                return (
                                                    <ProductLatest
                                                        key={item.id}
                                                        id={item.id}
                                                        image={`${process.env.REACT_APP_BASE_URL}${item.image}`}
                                                        name={language === "en" ? item.name_en : item.name_ar}
                                                        desc={language === "en" ? item.desc_en : item.desc_ar}
                                                        material={language === "en" ? item?.material?.name_en : item?.material?.name_ar}
                                                        link={item.id}
                                                        price={item.price}
                                                        sizes={item.sizes.map(size => size.size[0].size_name)}
                                                        active_color={item.color}
                                                        colors={item.colors.map(color => color.color)}
                                                        offer={item.discountvalue > 0}
                                                        discount={item.discountvalue}
                                                        rate={item.rate}
                                                        fav={isFav(item.id)}
                                                    />
                                                );
                                            })}
                                        </div>
                                    ) : productStatus === 'failed' ? (
                                        <div>{error}</div>
                                    ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="NO LATEST PRODUCTS" className='mt-24'/>}
                                </>
                            },
                        ]}
                            onChange={onChange} className='custom-tabs'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LatestArrevels;