import React, { useEffect } from "react";
import facebook2 from "../assets/facebook 2.png";
import linkedin from "../assets/linkedin .png";
import tiktok from "../assets/tiktok.png";
import whatsapp from "../assets/whatsapp.png";
import instagram from "../assets/instagram.png";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContacts } from "../redux/contactSlice";
import { fetchCategories } from "../redux/catSlice";

const Footer = () => {

  const { t, i18n } = useTranslation();

  const { language } = i18n;

  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.contacts.contacts);
  const contactsStatus = useSelector((state) => state.contacts.status);
  const error = useSelector((state) => state.contacts.error);

  useEffect(() => {
    if (contactsStatus === 'idle') {
      dispatch(fetchContacts());
    }
  }, [contactsStatus, dispatch]);

  const categories = useSelector((state) => state.categories.categories);
  const categoriesStatus = useSelector((state) => state.categories.status);

  useEffect(() => {
    if (categoriesStatus === 'idle') {
      dispatch(fetchCategories());
    }
  }, [categoriesStatus, dispatch]);

  // if (contactsStatus === 'loading') {
  //   return <div>Loading...</div>;
  // }

  if (contactsStatus === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (
    <div dir={language == "ar" ? 'rtl' : 'ltr'}>
      <div
        style={{ border: "1px solid #0000002B" }}
        className="px-24 pt-20 pb-10 max-lg:p-5 mx-24 max-lg:mx-5 mt-4 relative"
      >
        <div className="text-black grid grid-cols-4 max-lg:grid-cols-2 gap-4 px-14 max-lg:px-5">
          <div>
            <h2 className="text-xl max-lg:text-lg"
              style={{
                fontWeight: "700",
                fontFamily: "Comfortaa",
              }}
            >
              {t('SHOP')}
            </h2>
            <div style={{
              fontWeight: "300",
              fontFamily: "Comfortaa",
            }}
              className="my-4 text-lg max-lg:text-base"
            >
              {categories.slice(0, 4).map((category, index) => (
                <Link to={`/products?category=${category.id}`} className="block">{language === "en" ? category.name_en : category.name_ar}</Link>
              ))}

              <Link to="/sale" className="block"> {t('SALE')}</Link>
            </div>
          </div>

          <div>
            <h2 className="text-xl max-lg:text-lg"
              style={{
                fontWeight: "700",
                fontFamily: "Comfortaa",
              }}
            >
              {t('INFO')}
            </h2>
            <div
              style={{
                fontWeight: "300",
                fontFamily: "Comfortaa",
              }}
              className="my-4 text-lg max-lg:text-base"
            >
              <Link to="/" className="block">{t('NEED HELP ')}  {t('symbol')}  </Link>
              <Link to="/" className="block">{t('SHIPPING INFO')}</Link>
              <Link to="/" className="block">{t('FAQ')}</Link>
              <Link to="/contact" className="block">{t('CONTACT US')}!</Link>
            </div>
          </div>
          <div>
            <h2 className="text-xl max-lg:text-lg"
              style={{
                fontWeight: "700",
                fontFamily: "Comfortaa",
              }}
            >
              {t('ABOUT')}
            </h2>
            <div
              style={{
                fontWeight: "300",
                fontFamily: "Comfortaa",
              }}
              className="my-4 text-lg max-lg:text-base"
            >
              <Link to="/aboutUs" className="block">{t('ABOUT US')}</Link>
              <Link to="/privacypolicy" className="block"> {t('PRIVACY POLICY')}</Link>
              <Link to="/terms" className="block">{t('TERMS OF SERVICE')}</Link>
            </div>
          </div>
          <div>
            {" "}
            <h2 className="text-xl max-lg:text-lg"
              style={{
                fontWeight: "700",
                fontFamily: "Comfortaa",
              }}
            >
              {t('LINKS')}
            </h2>
            <div
              style={{
                fontWeight: "300",
                fontFamily: "Comfortaa",
              }}
              className="my-4 text-lg max-lg:text-base"
            >
              <Link to="/profile" className="block">{t('MY ACCOUNT')}</Link>
              <Link to="/" className="block">{t('SIZE GUIDE')}</Link>
            </div>
          </div>
        </div>
        <div className="cont-icon  flex items-center justify-end py-2 rounded-lg  ">
          <div className={`flex ${language == "ar" ? 'space-x-4' : 'space-x-4'} `}>
            <Link to={contacts?.facebook?.value}>
              <img className="mx-2" src={facebook2} />
            </Link>
            <Link to={contacts?.tiktok?.value}>
              <img src={tiktok} />
            </Link>
            <Link to={contacts?.linked?.value}>
              <img src={linkedin} />
            </Link>
            <Link to={contacts?.insta?.value}>
              <img src={instagram} />
            </Link>
          </div>
        </div>
      </div>

      <div dir={language == "ar" ? 'ltr' : 'ltr'} className="px-24 max-lg:p-5 flex justify-between items-center mt-3 mb-9 ">
        <h5 className="">{t('Copyright 2024, All Rights Reserved')} </h5>
        <div className=" flex items-center">
          <h4
            className=" text-center text-3xl max-lg:text-xl"
            style={{
              fontWeight: "700",
              fontFamily: "Cinzel Decorative",
            }}
          >
            {" "}
            V
          </h4>
          <span
            className="text-center flex justify-center items-center text-3xl max-lg:text-xl"
            style={{
              fontWeight: "700",
              fontFamily: "Cinzel Decorative",
            }}
          >
            INCI
          </span>
        </div>
        <span className="max-lg:hidden" style={{ width: "18%" }}></span>
      </div>
    </div>
  );
};

export default Footer;
