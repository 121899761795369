import React from 'react';
import Header from './Header';
// import Sec from './Sec';
// import Cards from "./Cards";
import Footer from './Footer';
import ScrollToTopButton from './ScrollToTopButton';

const Layout = ({ children }) => (
  <div>
    <Header />
    <main>{children}</main>
    <Footer />
    <ScrollToTopButton />
  </div>
);

export default Layout;
