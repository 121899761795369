// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import paymentServices from '../services/paymentServices';

// const initialState = {
//   paymentResponse: null,
//   status: 'idle',
//   error: null,
// };

// export const postPayment = createAsyncThunk(
//   'payment/postPayment',
//   async ({ data, token }, thunkAPI) => {
//     try {
//       const response = await paymentServices.postPayment(data, token);
//       return response;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.message);
//     }
//   }
// );

// const paymentSlice = createSlice({
//   name: 'payment',
//   initialState,
//   reducers: {},
//   extraReducers: (builder) => {
//     builder
//       .addCase(postPayment.pending, (state) => {
//         state.status = 'loading';
//       })
//       .addCase(postPayment.fulfilled, (state, action) => {
//         state.status = 'succeeded';
//         state.paymentResponse = action.payload;
//       })
//       .addCase(postPayment.rejected, (state, action) => {
//         state.status = 'failed';
//         state.error = action.payload;
//       });
//   },
// });

// export default paymentSlice.reducer;


import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import paymentServices from '../services/paymentServices';

const initialState = {
  paymentResponse: null,
  status: 'idle',
  error: null,
};

export const postPayment = createAsyncThunk(
  'payment/postPayment',
  async ({ data, token }, thunkAPI) => {
    try {
      const response = await paymentServices.postPayment(data, token);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postPayment.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postPayment.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.paymentResponse = action.payload;
      })
      .addCase(postPayment.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default paymentSlice.reducer;
