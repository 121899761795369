import React from 'react';
import { useInView } from 'react-intersection-observer';

const AnimatedComponent = ({ children, animationClass }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger the animation only once
    threshold: 0.1, // 10% of the element should be in view
  });

  return (
    <div
      ref={ref}
      className={`${inView ? animationClass : ''} animate-on-scroll`}
    >
      {children}
    </div>
  );
};

export default AnimatedComponent;
