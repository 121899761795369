import React, { useState } from "react";
import Navbar2 from '../components/Navbar2';
import "../styles/profile.css";
import { useTranslation } from 'react-i18next';
import ForgotPasswordForm from "../components/ForgotPasswordForm";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ForgotPassword = () => {

    const { t, i18n } = useTranslation();

    const { language } = i18n;

    const { isAuthenticated } = useSelector((state) => state.auth);

    if (isAuthenticated) {
        return <Navigate to="/profile" />;
    }

    return (
        <div dir={language == 'en' ? 'ltr' : 'rtl'}>
            <Navbar2 />
            <ForgotPasswordForm />
        </div>
    )
}

export default ForgotPassword
