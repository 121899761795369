import React, { useEffect } from 'react'
import { fetchTerms } from '../redux/termsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Term = () => {

    const { t, i18n } = useTranslation();

    const { language } = i18n;

    const dispatch = useDispatch();
    const terms = useSelector((state) => state.terms.terms);
    const termsStatus = useSelector((state) => state.terms.status);
    const error = useSelector((state) => state.terms.error);

    useEffect(() => {
        if (termsStatus === 'idle') {
            dispatch(fetchTerms());
        }
    }, [termsStatus, dispatch]);

    return (
        <div>
            <div className='bg-black h-[80vh] max-lg:h-[50vh] m-auto'>
                <h2 className='text-4xl text-white text-center pt-48 max-lg:pt-20'>{t('VINCI_TERMS')}</h2>
            </div>

            <div className='mt-8 px-24 max-lg:px-5'>
                <h3 className='text-2xl'>{t('Published')} {terms.from}</h3>
                <p className='my-5 text-[#747474]'>{terms.data}</p>
            </div>
        </div>
    )
}

export default Term
