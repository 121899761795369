import React, { useState } from "react";
import Navbar2 from '../components/Navbar2';
import "../styles/profile.css";
import { useTranslation } from 'react-i18next';
import SignUpForm from "../components/SignUpForm";

const SignUp = () => {

    const { t, i18n } = useTranslation();

    const { language } = i18n;

    return (
        <div dir={language == 'en' ? 'ltr' : 'rtl'}>
            <Navbar2 />
            <SignUpForm />
        </div>
    )
}

export default SignUp