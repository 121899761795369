import React from 'react';
import { Link } from 'react-router-dom';

const Cat = ({ image, key , link , alt, catName, modelsNumber, backgroundColor }) => {
    return (
        <Link key={key} to={link} className="relative group flex justify-center items-center"
            style={{ backgroundColor: backgroundColor, minHeight: "458px" }}
        >
            <img
                className="object-center object-cover pt-5 pb-28"
                src={image}
                alt={alt}
                width={250}
                height={250}
            />
            <p className="bottom-4 z-10 absolute text-base font-medium text-gray-800 py-4 px-4 w-4/5 bg-white text-center">
                <span
                    className="text-xl font-bold text-slate-900"
                    style={{
                        fontWeight: "700",
                        fontFamily: "Comfortaa",
                        color: "black",
                    }}
                >
                    {catName}
                </span>
                <p
                    className="text-lg font-bold text-slate-900"
                    style={{
                        fontWeight: "700",
                        fontFamily: "Comfortaa",
                        color: "#808080",
                    }}
                >
                    {modelsNumber} Model Item
                </p>
            </p>
        </Link>
    );
}

export default Cat;