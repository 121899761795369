import React from 'react'
import Navbar2 from '../components/Navbar2';
import CheckoutComp from '../components/CheckoutComp';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const Checkout = () => {

  const { t, i18n } = useTranslation();

  const { language } = i18n;

  const { isAuthenticated } = useSelector((state) => state.auth);

  if (!isAuthenticated) {
    return <Navigate to="/signup" />;
  }

  return (
    <div dir={language == 'en' ? 'ltr' : 'rtl'}>
      <Navbar2 />
      <CheckoutComp />
    </div>
  )
}

export default Checkout