import React from 'react'
import Navbar2 from '../components/Navbar2';
import ContactComp from '../components/ContactComp';
import { useTranslation } from 'react-i18next';

const Contact = () => {

  const { t, i18n } = useTranslation();

  const { language } = i18n;

  return (
    <div dir={language == 'en' ? 'ltr' : 'rtl'}>
      <Navbar2 />
      <ContactComp />
    </div>
  )
}

export default Contact
