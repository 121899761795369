import React, { useEffect } from 'react'
import Product from "./Product";
import "../styles/sale.css";
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../redux/productSlice';
import { Empty, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

const MainSale = () => {

  const { t, i18n } = useTranslation();

  const { language } = i18n;

  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.products);
  const productStatus = useSelector((state) => state.products.status);
  const error = useSelector((state) => state.products.error);
  const favorites = useSelector(state => state.products.favorites);

  const isFav = (id) => {
    const ay7aga = favorites.find((item) => item.id === id);
    if (ay7aga) return true
    return false
  }

  useEffect(() => {
    if (productStatus === 'idle') {
      dispatch(fetchProducts());
    }
  }, [productStatus, dispatch]);

  const discountedProducts = products.filter(product => product.discountvalue > 0);

  return (
    <div className='mt-2'>
      <div className='relative bg_sale' >
        <button className=" absolute bottom-20 left-48 max-lg:bottom-5 max-lg:left-10  text-white  bg-[#d1887f] lg:font-bold py-4 px-20 max-lg:py-1 max-lg:px-3 items-center max-lg:text-base ">
          {t('EXPLORE')}
        </button>
      </div>

      <div className='px-24 max-lg:px-5'>
        <p className='mt-10 text-2xl max-lg:text-xl text-center'> {t('All_Discount_Products')}</p>

        {productStatus === 'loading' ? (
          <div className="text-center my-44"><Spin size="large" /></div>
        ) : productStatus === 'succeeded' ? (
          <div className={`grid grid-cols-4 max-lg:grid-cols-1 gap-4 px-10 max-lg:px-10 mt-10`}>
            {discountedProducts == 0 ?
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className='text-center col-span-4 text-lg mb-10' />
              :
              discountedProducts.map((product) => (
                <div key={product.id} className='mb-8'>
                  <Product
                    key={product.id}
                    id={product.id}
                    image={`${process.env.REACT_APP_BASE_URL}${product.image}`}
                    name={language == "en" ? product.name_en : product.name_ar}
                    link={product.id}
                    price={product.price}
                    sizes={product.sizes.map(size => size.size[0].size_name)}
                    active_color={product.color}
                    colors={product.colors.map(color => color.color)}
                    offer={product.discountvalue > 0}
                    discount={product.discountvalue}
                    rate={product.rate}
                    fav={isFav(product.id)}
                  />
                </div>
              ))}
          </div>
        ) : productStatus === 'failed' ? (
          <div>{error}</div>
        ) : null}
      </div>
    </div>
  )
}

export default MainSale
