import React, { startTransition } from 'react';
import { useTranslation } from 'react-i18next';

const Translate = () => {

    const { t, i18n } = useTranslation();

    const { language } = i18n;

    const changeLanguage = (lng) => {
        startTransition(() => {
            i18n.changeLanguage(lng);
        });
    };

    return (
        <div className={`mt-24 max-lg:mt-10 mb-44 max-lg:mb-10 lg:min-h-[252px] ${language == "en" ? 'lg:pr-20' : 'lg:pl-20'} `}>
            {/* <h3 className='flex justify-center pr-56 text-2xl'>Change the language</h3> */}
            <div className='lg:flex justify-center text-center items-center mt-5'>
                <button onClick={() => changeLanguage('en')} className='bg-white mx-8 rounded-lg py-10 lg:px-20 max-lg:w-[170px] font-semibold'>English</button>
                <button onClick={() => changeLanguage('ar')} className='bg-white rounded-lg py-10 lg:px-20 max-lg:w-[170px] font-semibold max-lg:mt-5'>عربي</button>
            </div>
        </div>
    )
}

export default Translate
