import React, { useEffect, useState } from "react";
import { Collapse } from "antd";
import {
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "../styles/Faq.css";
import { useDispatch, useSelector } from 'react-redux';
import { fetchQuestions } from '../redux/faqSlice';
import { useTranslation } from 'react-i18next';

const Faq = () => {

  const { t, i18n } = useTranslation();

  const { language } = i18n;

  const [collapsed, setCollapsed] = useState(false);
  let [open, setOpen] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    {
      collapsed ? <PlusOutlined /> : <MinusOutlined />;
    }
  };
  const customExpandIcon = ({ isActive }) =>
    isActive ? <MinusOutlined className="plus-icon mt-4" /> : <PlusOutlined className="plus-icon mt-4" />;

  const dispatch = useDispatch();
  const questions = useSelector((state) => state.questions.questions);
  const questionsStatus = useSelector((state) => state.questions.status);
  const error = useSelector((state) => state.questions.error);

  useEffect(() => {
    if (questionsStatus === 'idle') {
      dispatch(fetchQuestions());
    }
  }, [questionsStatus, dispatch]);

  return (
    <div
      className="px-24 max-lg:px-5 my-14"
      style={{
        fontWeight: "700",
        fontFamily: "Comfortaa",
      }}
    >
      <h2 className="text-4xl"
        style={{
          fontWeight: "700",
          fontFamily: "Cinzel Decorative",
          color: " #1A1818",
          textAlign: "center",
        }}
      >
         {t('FAQ')}
      </h2>
      <h3 className="text-4xl my-6"
        style={{
          fontWeight: "400",
          fontFamily: "Chilanka",
          textAlign: "center",
        }}
      >
         {t('Question_look_her')}
      </h3>
      <div className="bg_questions flex justify-center mx-auto">
        <Collapse
          defaultActiveKey={["1"]}
          inlineCollapsed={collapsed}
          // items={items}
          expandIcon={customExpandIcon}
          // expandIcon={({ isActive }) => <PlusOutlined className="size-12" />}
          bordered={false}
          style={{
            fontWeight: "400",
            fontFamily: "Comfortaa",
            color: " black",
          }}
          className="text-2xl max-lg:text-lg w-3/4 max-lg:w-full"
        >
          {questions.map((question, index) => (
            <Collapse.Panel header={language == "en" ? question.title_en : question.title_ar} key={index + 1}>
              <h4 className="text-xl max-lg:text-base pl-6 pr-10 max-lg:pr-6">
                {language == "en" ? question.question_en : question.question_ar}
              </h4>
            </Collapse.Panel>
          ))}

        </Collapse>
      </div>
    </div>
  )
}

export default Faq
