import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import './App.css';
import Sale from './pages/Sale';
import Categories from './pages/Categories';
import Favorites from './pages/Favorites';
import AboutUs from './pages/AboutUs';
import ProductDetails from './pages/ProductDetails';
import ProductList from './pages/ProductList';
import CartPage from './pages/CartPage';
import Checkout from './pages/Checkout';
import Contact from './pages/Contact';
import Profile from './pages/Profile';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from './redux/authSlice';
import Pay from './pages/Pay';
import Paypal from './pages/Paypal';
import Creditcard from './pages/Creditcard';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import ForgotPassword from './pages/ForgotPassword';

const App = () => {

  const { user, remember_token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // console.log(remember_token);

  const handleLogout = () => {
    dispatch(logout());
  };

  const { t, i18n } = useTranslation();

  const { language } = i18n;

  // const changeLanguageButton = (language) => {
  //   i18n.changeLanguage(language);
  //   localStorage.setItem('selectedLanguage', JSON.stringify(language));
  // };

  // useEffect(() => {
  //   const storedLanguage = localStorage.getItem('selectedLanguage');
  //   if (storedLanguage) {
  //     changeLanguageButton(JSON.parse(storedLanguage));
  //   } else {
  //     changeLanguageButton('en');
  //   }
  // }, []);

  return (
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sale" element={<Sale />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/favorites" element={<Favorites />} />
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/pay" element={<Pay />} />
            <Route path="/paypal" element={<Paypal />} />
            <Route path="/creditcard" element={<Creditcard />} />
            
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            
            <Route path="/profile" element={<Profile />} />
            <Route path="/products" element={<ProductList />} />
            <Route path="/products/:id" element={<ProductDetails />} />

            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
          </Routes>
        </Layout>
      </Router>
  )
};

export default App;
