import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../redux/catSlice';

import Cat from './Cat';


const Categories = () => {


    const dispatch = useDispatch();
    const categories = useSelector((state) => state.categories.categories);
    const categoriesStatus = useSelector((state) => state.categories.status);
    const error = useSelector((state) => state.categories.error);

    useEffect(() => {
        if (categoriesStatus === 'idle') {
            dispatch(fetchCategories());
        }
    }, [categoriesStatus, dispatch]);

    const { t, i18n } = useTranslation();

    const { language } = i18n;

    return (
        <div className='px-24 max-lg:px-5 my-4'>
            <div className='grid grid-cols-3 max-lg:grid-cols-1 gap-4'>
                {categories.map((category, index) => (
                    <Cat
                        key={index}
                        link={`/products?category=${category.id}`}
                        image={`${process.env.REACT_APP_BASE_URL}${category.image}`}
                        alt={category.alt}
                        catName={language === "en" ? category.name_en : category.name_ar}
                        modelsNumber={category.items.length}
                        backgroundColor={index % 2 === 0 ? '#e5e9ec' : '#dbd4c4'}
                    />
                ))}
            </div>
        </div>
    )
}

export default Categories
