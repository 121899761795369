// const postPayment = async (data, token) => {
//     const response = await fetch('https://vinci.dtagdev.com/api/pay_cart', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${token}`,
//       },
//       body: JSON.stringify(data),
//     });
  
//     if (!response.ok) {
//       throw new Error('Network response was not ok');
//     }
  
//     return response.json();
//   };
  
//   export default {
//     postPayment,
//   };
  

// import { PrivateAxios } from './axiosInstances';
import { PrivateAxios } from '../api';

const postPayment = async (data) => {
  const response = await PrivateAxios.post('/api/pay_cart', data);
  
  if (response.status !== 200) {
    throw new Error('Network response was not ok');
  }
  
  return response.data;
};

export default {
  postPayment,
};