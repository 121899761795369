// src/components/ProfileForm.js
import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined, CaretDownOutlined } from "@ant-design/icons";
import { Button, Form, Select } from "antd";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import ProfileInputs from "../ProfileInputs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchCountries } from "../../redux/countrySlice";
import { fetchCities } from "../../redux/citySlice";
import { createProfile } from "../../redux/authSlice";
import { fetchProfile } from "../../redux/profileSlice";

const ProfileForm = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const { user, isLoading } = useSelector((state) => state.auth);
  const profile = useSelector((state) => state.profile.profile.data);
  console.log(profile);
  const profileStatus = useSelector((state) => state.profile.status);
  const error = useSelector((state) => state.profile.error);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [address, setAddress] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (profileStatus === "idle") {
      dispatch(fetchProfile());
    }
  }, [profileStatus, dispatch]);

  useEffect(() => {
    if (profile?.user?.firstname) {
      setFirstname(profile.user.firstname);
    }
    if (profile?.user?.lastname) {
      setLastname(profile.user.lastname);
    }
    if (profile?.user?.email) {
      setEmail(profile.user.email);
    }
    if (profile?.user?.phone) {
      setPhone(profile.user.phone);
    }
    if (profile?.user?.country_id) {
      setSelectedCountry(profile.user.country_id);
    }
    if (profile?.user?.city_id) {
      setSelectedCity(profile.user.city_id);
    }
    if (profile?.user?.address) {
      setAddress(profile.user.address);
    }
  }, [profile]);

  const [focused, setFocused] = useState(false);

  const countries = useSelector((state) => state.countries.countries);
  const countriesStatus = useSelector((state) => state.countries.status);
  const errorCountries = useSelector((state) => state.countries.error);

  const cities = useSelector((state) => state.cities.cities);
  const citiesStatus = useSelector((state) => state.cities.status);
  const errorCities = useSelector((state) => state.cities.error);

  useEffect(() => {
    if (countriesStatus === "idle") {
      dispatch(fetchCountries());
    }
  }, [countriesStatus, dispatch]);

  useEffect(() => {
    if (selectedCountry) {
      dispatch(fetchCities(selectedCountry));
    }
  }, [selectedCountry, dispatch]);

  const handleCountrySelect = (value) => {
    setSelectedCountry(value);
    setSelectedCity(null);
  };

  const handleCitySelect = (value) => {
    setSelectedCity(value);
  };

  if (!user) {
    return <div>No user data available</div>;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      firstname,
      lastname,
      email,
      address,
      phone,
      country_id: selectedCountry,
      city_id: selectedCity,
    };
    dispatch(createProfile(data));
  };

  const selectedCountryName =
    language == "en"
      ? countries.find((country) => country.id === selectedCountry)?.name_en
      : countries.find((country) => country.id === selectedCountry)?.name;
  const selectedCityName =
    language == "en"
      ? cities.find((city) => city.id === selectedCity)?.name_en
      : cities.find((city) => city.id === selectedCity)?.name;

  return (
    <div className="profileContainer lg:min-h-[502px]">
      <div
        className={`w-3/5 max-lg:w-full mx-auto ${
          language == "en" ? "lg:pr-14" : "lg:pl-14"
        }`}
      >
        <form className="mt-4" onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 max-lg:grid-cols-1 gap-6 max-lg:gap-0">
            <ProfileInputs
              label={t("First name")}
              value={firstname}
              onChange={setFirstname}
            />
            <ProfileInputs
              label={t("Last name")}
              value={lastname}
              onChange={setLastname}
            />
          </div>

          <div>
            <ProfileInputs
              label={t("Email address")}
              value={email}
              onChange={setEmail}
            />
          </div>

          {/* <div>
            <Form.Item
              className={`${language == "en" ? 'floating-label-input' : 'floating-label-input-ar'} ${language == "en" ? 'react-tel-input selected-flag ' : 'react-tel-input-ar selected-flag '} focused phone_number`}
              label={t('Phone number')}
            >
              <PhoneInput
                country={"eg"}
                value={phone}
                onChange={(phone) => setPhone(phone)}
                disableCountryCode={false}
                onlyCountries={["eg", "us", "gb"]}
                preferredCountries={["eg"]}
                placeholder={t('Enter phone number')}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                className={`bg-white p-2 ${language == "en" ? 'floating-label-input' : 'floating-label-input-ar'} ${language == "en" ? 'profileStyle react-tel-input form-control' : 'profileStyle-ar react-tel-input form-control'} ${language == "en" ? 'react-tel-input selected-flag ' : 'react-tel-input-ar selected-flag '} rounded placeholder:text-black placeholder:text-xl placeholder:font-normal placeholder:font-Arimo`}
              />
            </Form.Item>
          </div> */}

          <div className="relative -top-3">
            <label
              className={`${
                language === "en"
                  ? "floating-label-input"
                  : "floating-label-input floating-label-input-ar"
              } focused font-semibold relative top-2 right-2`}
            >
              {t("Phone number")}
            </label>

            <PhoneInput
              defaultCountry="eg"
              value={phone}
              onChange={(phone) => setPhone(phone)}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              className={`bg-white p-2 rounded placeholder:text-black placeholder:text-xl placeholder:font-normal placeholder:font-Arimo ${
                language === "en" ? "en_phone" : "ar_phone"
              }`}
            />
          </div>

          <div className="mt-5"></div>

          <div className="grid grid-cols-2 max-lg:grid-cols-1 gap-6 mb-6">
            <Select
              value={selectedCountryName}
              onChange={handleCountrySelect}
              className="rounded placeholder:text-black placeholder:text-xl placeholder:font-normal placeholder:font-Arimo"
              placeholder={t("COUNTRY")}
              options={countries.map((country) => ({
                label: language == "en" ? country.name_en : country.name,
                value: country.id,
              }))}
              suffixIcon={<CaretDownOutlined />}
            />
            <Select
              value={selectedCityName}
              onChange={handleCitySelect}
              className="rounded placeholder:text-black placeholder:text-xl placeholder:font-normal placeholder:font-Arimo"
              placeholder={t("City")}
              options={cities.map((city) => ({
                label: language == "en" ? city.name_en : city.name,
                value: city.id,
              }))}
              suffixIcon={<CaretDownOutlined />}
            />
          </div>

          <ProfileInputs
            label={t("Address")}
            value={address}
            onChange={setAddress}
          />

          <Button
            htmlType="submit"
            className="border-0 py-5 px-14 rounded-lg flex lg:ml-auto max-lg:w-full save_btn"
          >
            {isLoading ? t("loading") : t("SAVE")}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm;
