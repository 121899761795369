import axios from 'axios';

export const publicAxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 10000,
    headers: { 'Content-Type': 'application/json' },
});

export const PrivateAxios = axios.create({
  baseURL: 'https://vinci.dtagdev.com',
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' },
});

PrivateAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['token'] = `${token}`;
    }
    console.log('Updated Config headers:', config.headers);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

