import React from 'react'
import Navbar2 from '../components/Navbar2';
import CartsComp from '../components/CartsComp';
import { useTranslation } from 'react-i18next';

const CartPage = () => {

  const { t, i18n } = useTranslation();

  const { language } = i18n;

  return (
    <div dir={language == 'en' ? 'ltr' : 'rtl'}>
      <Navbar2 />
      <CartsComp />
    </div>
  )
}

export default CartPage
