import { PrivateAxios, publicAxios } from '../api';

class ProfileServices {
  async getAll() {
    const response = await PrivateAxios.get('/api/get_user_orders');
    return response.data;
  }
}

const profileServices = new ProfileServices();
export default profileServices;