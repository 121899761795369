import React from 'react'
import Navbar2 from '../components/Navbar2';
import AboutComp from '../components/AboutComp';
import { useTranslation } from 'react-i18next';

const AboutUs = () => {

    const { t, i18n } = useTranslation();

  const { language } = i18n;

    return (
        <div dir={language == 'en' ? 'ltr' : 'rtl'}>
            <Navbar2 />
            <AboutComp />
        </div>
    )
}

export default AboutUs
