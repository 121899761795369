import React, { useState } from "react";
import "../styles/Header.css";
import Link, { NavLink } from "react-router-dom";
import { Menu, Button, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { SubMenu } = Menu;

const Nav2 = () => {

  const { t, i18n } = useTranslation();

  const { language } = i18n;

  let Links = [
    { name: "HOME", name_ar: "الرئيسية", link: "/" },
    { name: "PRODUCTS", name_ar: "المنتجات", link: "/products" },
    { name: "SALE", name_ar: "تخفيضات", link: "/sale" },
    { name: "CONTACT", name_ar: "تواصل معنا", link: "/contact" },
    { name: "ABOUT US", name_ar: "عنا", link: "/aboutUs" },
  ];

  const [open, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  return (
    <div dir={language == "ar" && "ltr"}>
      <div className="md:flex items-center justify-center bg-white py-4 px-24 max-lg:px-5 max-lg:hidden lg:flex">

        <div>
          <div
            className="font-bold text-3xl max-lg:text-xl cursor-pointer flex items-center justify-center my-4" style={{ fontFamily: "Cinzel Decorative" }}

          >
            <p style={{ fontWeight: "700", fontSize: "40px" }}> V</p>
            <span>INCI</span>
          </div>
          <ul
          dir={language == "ar" && "rtl"}
            className={`md:flex  absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in`}
          >
            {Links.map((link) => (
              <li
                key={link.name}
                className="md:ml-8 text-lg md:my-0 my-7 font-[ Cinzel Decorative]"
              >
                <NavLink to={link.link} className={({ isActive }) =>
                  isActive
                    ? 'hover:#AD834E active:#AD834E duration-500 text-[#AD834E]'
                    : 'hover:#AD834E active:#AD834E duration-500'
                }>
                  {language == "en" ? link.name : link.name_ar}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className=" bg-white nav flex items-center justify-between py-4 max-lg:px-5 lg:hidden max-lg:flex">

        <div
          className="font-bold text-3xl max-lg:text-xl cursor-pointer flex items-center text-black" style={{ fontFamily: "Cinzel Decorative" }}>
          <p style={{ fontWeight: "700", fontSize: "40px" }}> V</p>
          <span>INCI</span>
        </div>

        <div>
          <Button type="primary" className="bg-black" onClick={showDrawer}>
            <MenuOutlined />
          </Button>
          <Drawer
            title=""
            placement="right"
            onClose={onClose}
            visible={open}
          >
            <Menu
              // mode="inline"
              className="border-e-0"
              dir={language == "ar" && "rtl"}
            >
              {Links.map((link) => (
                  <NavLink to={link.link} key={link.name} className={({ isActive }) =>
                    isActive
                      ? 'hover:#AD834E active:#AD834E duration-500 text-lg block mb-7 font-[ Cinzel Decorative] text-[#AD834E]'
                      : 'hover:#AD834E active:#AD834E duration-500 text-lg block mb-7 font-[ Cinzel Decorative]'
                  }>
                    {language == "en" ? link.name : link.name_ar}
                  </NavLink>
              ))}
            </Menu>
          </Drawer>
        </div>

      </div>
    </div>
  );
};

export default Nav2;