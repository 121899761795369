import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import PassInput from '../PassInput';
import authAPI from '../../services/api';

const ResetPassword = () => {
    const { t, i18n } = useTranslation();
    const { language } = i18n;
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await authAPI.resetPassword({ old_password: oldPassword, password });
            message.success(t('Password reset successfully'));
        } catch (error) {
            message.error(t('Failed to reset password'));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='lg:px-56 mt-16 max-lg;mt-7 max-lg:my-5 lg:min-h-[462px]'>
            <h3 className='text-2xl max-lg:text-xl mb-5'>{t("Reset_Password")}</h3>
            <form onSubmit={handleResetPassword}>
                <PassInput placeholder={t('Old_Password')} value={oldPassword} onChange={setOldPassword} name="old_password" />
                <PassInput placeholder={t('New_Password')} value={password} onChange={setPassword} name="password" />
                <Button htmlType="submit" className="border-0 py-5 px-14 rounded-lg flex lg:ml-auto max-lg:w-full save_btn">
                    {isLoading ? t('Loading...') : t('SAVE')}
                </Button>
            </form>
        </div>
    );
};

export default ResetPassword;
