import React from 'react'
import Navbar from '../components/Navbar';
import Main from '../components/Main';
import BestSellers from '../components/BestSellers';
import CategoriesSection from '../components/CategoriesSection';
import Statistics from '../components/Statistics';
import LatestArrevels from '../components/LatestArrevels';
import Faq from '../components/Faq';
import { useTranslation } from 'react-i18next';

const Home = () => {

  const { t, i18n } = useTranslation();

  const { language } = i18n;

  return (
    <div dir={language == 'en' ? 'ltr' : 'rtl'}>
      <Navbar />
      <Main />
      <BestSellers />
      <CategoriesSection />
      <Statistics />
      <LatestArrevels />
      <Faq />
    </div>

  )
}

export default Home
