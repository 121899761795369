import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addFavorite, removeFavorite } from "../redux/productSlice";
import "../styles/fav.css";
import Product from "./Product";
import { useTranslation } from 'react-i18next';

const FavComp = () => {

  const favorites = useSelector(state => state.products.favorites);
  const status = useSelector((state) => state.products.status);
  const dispatch = useDispatch();

  console.log(favorites)

  const { t, i18n } = useTranslation();

    const { language } = i18n;

  // useEffect(() => {
  //   if (status === "idle") {
  //     dispatch(fetchFavourites());
  //   }
  // }, [status, dispatch]);

  // const addToCartHandler = (productId) => {
  //   const product = favourites.find(p => p.id === productId);
  //   dispatch(addItem(product));
  // };

  return (
    <div className="px-24 max-lg:px-5">
      <div className="fav_bg mx-auto mt-4">
        <h2 className="text-center text-3xl max-lg:text-xl font-semibold pt-4 max-lg:pt-2">
          {t('FAVORITE')}
        </h2>
      </div>

      <div class=" grid grid-cols-4 max-lg:grid-cols-1 gap-4 px-10 max-lg:px-10 mt-5">
        {favorites.map(product => (
          <div className='mb-8'>
            <Product
              key={product.id}
              id={product.id}
              image={`${process.env.REACT_APP_BASE_URL}${product.image}`}
              name={language == "en" ? product.name_en : product.name_ar}
              link={product.id}
              price={product.price}
              sizes={product.sizes.map(size => size.size[0].size_name)}
              active_color={product.color}
              colors={product.colors.map(color => color.color)}
              offer={product.discountvalue > 0 ? true : false}
              discount={product.discountvalue}
              rate={product.rate}
              fav={true}
            />
          </div>
        ))}
      </div>


    </div>
  );
};

export default FavComp;

