import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const Category = ({image , key, link ,alt , catName , modelsNumber}) => {

    const { t, i18n } = useTranslation();

    const { language } = i18n;

    return (
        <Link to={link}
            className="relative group flex justify-center items-center py-8 mt-5"
            style={{ backgroundColor: "#E5E9EC", minHeight: "258px"}}
        >
            <img
                className="object-center object-cover"
                src={image}
                alt={alt}
                width={250}
                height={250}
            />
            <p className="  bottom-4 z-10 absolute text-base font-medium  text-gray-800  py-4 px-4 w-80 bg-[#ffffff9c] text-center ">
                <span
                    className="text-xl font-bold text-slate-900"
                    style={{
                        fontWeight: "700",
                        fontFamily: "Comfortaa",
                        color: "black",
                    }}
                >
                    {catName}
                </span>
                <p
                    className="text-lg font-bold text-slate-900"
                    style={{
                        fontWeight: "700",
                        fontFamily: "Comfortaa",
                        color: "#808080",
                    }}
                >
                    {modelsNumber} {t('Model_Item')}
                </p>
            </p>
        </Link>
    )
}

export default Category
