import React from 'react'
import Navbar2 from '../components/Navbar2';
import Term from '../components/Term';
import { useTranslation } from 'react-i18next';

const Terms = () => {

  const { t, i18n } = useTranslation();

    const { language } = i18n;

  return (
    <div dir={language == 'en' ? 'ltr' : 'rtl'}>
      <Navbar2 />
      <Term />
    </div>
  )
}

export default Terms
