import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { publicAxios } from '../api';

const initialState = {
  subcategories: [],
  status: 'idle',
  error: null,
};

export const fetchsubcategories = createAsyncThunk('subcategories/fetchsubcategories', async () => {
  const response = await publicAxios.get('/api/get_subcat');
  return response.data.data;
});

const subCatSlice = createSlice({
  name: 'subcategories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchsubcategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchsubcategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.subcategories = action.payload;
      })
      .addCase(fetchsubcategories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default subCatSlice.reducer;