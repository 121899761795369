import React from 'react'
import Navbar2 from '../components/Navbar2';
import FavComp from '../components/FavComp';
import { useTranslation } from 'react-i18next';

const Favorites = () => {

  const { t, i18n } = useTranslation();

  const { language } = i18n;

  return (
    <div dir={language == 'en' ? 'ltr' : 'rtl'}>
      <Navbar2 />
      <FavComp />
    </div>
  )
}

export default Favorites
