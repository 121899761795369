import React, { useState, useEffect } from "react";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Menu, Spin, Switch, Checkbox, Input } from "antd";
import adjust from "../assets/adjust.png";
import lineside from "../assets/lineside.png";
import ProductLatest from "../components/ProductLatest";
import Navbar2 from '../components/Navbar2';
import "../styles/ProductList.css";
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../redux/productSlice';
import { useTranslation } from 'react-i18next';
import { fetchCategories } from "../redux/catSlice";
import { fetchsubcategories } from "../redux/subCatSlice";

import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const ProductList = () => {
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.categories.categories);
    const subcategories = useSelector((state) => state.subcategories.subcategories);
    const categoriesStatus = useSelector((state) => state.categories.status);
    const subcategoriesStatus = useSelector((state) => state.subcategories.status);

    useEffect(() => {
        if (categoriesStatus === 'idle') {
            dispatch(fetchCategories());
        }
    }, [categoriesStatus, dispatch]);

    useEffect(() => {
        if (subcategoriesStatus === 'idle') {
            dispatch(fetchsubcategories());
        }
    }, [subcategoriesStatus, dispatch]);

    const { t, i18n } = useTranslation();
    const { language } = i18n;

    const [selectedCategories, setSelectedCategories] = useState(new Set());
    const [selectedSubCategories, setSelectedSubCategories] = useState(new Set());
    const [selectedMaterials, setSelectedMaterials] = useState(new Set());
    const [selectedColors, setSelectedColors] = useState(new Set());
    const [priceRange, setPriceRange] = useState([0, Infinity]);

    const products = useSelector((state) => state.products.products);
    const productStatus = useSelector((state) => state.products.status);
    const error = useSelector((state) => state.products.error);
    const favorites = useSelector(state => state.products.favorites);

    useEffect(() => {
        if (categoriesStatus === 'idle') {
            dispatch(fetchCategories());
        }
    }, [categoriesStatus, dispatch]);

    useEffect(() => {
        if (subcategoriesStatus === 'idle') {
            dispatch(fetchsubcategories());
        }
    }, [subcategoriesStatus, dispatch]);

    const location = useLocation();

    useEffect(() => {
        const parsed = queryString.parse(location.search);
        if (parsed.category) {
            const categoryId = parseInt(parsed.category);
            setSelectedCategories(new Set([categoryId]));
        }
    }, [location.search]);

    const handleCategoryChange = (categoryId) => {
        const newSelectedCategories = new Set(selectedCategories);
        if (newSelectedCategories.has(categoryId)) {
            newSelectedCategories.delete(categoryId);
            const newSelectedSubCategories = new Set(selectedSubCategories);
            subcategories
                .filter(subcategory => subcategory.cat_id === categoryId)
                .forEach(subcategory => newSelectedSubCategories.delete(subcategory.id));
            setSelectedSubCategories(newSelectedSubCategories);
        } else {
            newSelectedCategories.add(categoryId);
        }
        setSelectedCategories(newSelectedCategories);
    };

    const handleSubCategoryChange = (subcategoryId) => {
        const newSelectedSubCategories = new Set(selectedSubCategories);
        if (newSelectedSubCategories.has(subcategoryId)) {
            newSelectedSubCategories.delete(subcategoryId);
        } else {
            newSelectedSubCategories.add(subcategoryId);
        }
        setSelectedSubCategories(newSelectedSubCategories);
    };

    const handleMaterialChange = (material) => {
        const newSelectedMaterials = new Set(selectedMaterials);
        if (newSelectedMaterials.has(material)) {
            newSelectedMaterials.delete(material);
        } else {
            newSelectedMaterials.add(material);
        }
        setSelectedMaterials(newSelectedMaterials);
    };

    const handleColorChange = (color) => {
        const newSelectedColors = new Set(selectedColors);
        if (newSelectedColors.has(color)) {
            newSelectedColors.delete(color);
        } else {
            newSelectedColors.add(color);
        }
        setSelectedColors(newSelectedColors);
    };

    const handlePriceChange = (e, type) => {
        const value = parseFloat(e.target.value);
        if (type === 'min') {
            setPriceRange([value, priceRange[1]]);
        } else {
            setPriceRange([priceRange[0], value]);
        }
    };

    const filteredProducts = products.filter(product => {
        const matchesCategory = selectedCategories.size ? selectedCategories.has(product.cat_id) : true;
        const matchesSubCategory = selectedSubCategories.size ? selectedSubCategories.has(product.subcat_id) : true;
        const matchesMaterial = selectedMaterials.size ? product.material && selectedMaterials.has(product.material.name_en) : true;
        const matchesColor = selectedColors.size ? product.colors.some(color => selectedColors.has(color.color)) : true;
        const matchesPrice = product.price >= priceRange[0] && product.price <= priceRange[1];
        return matchesCategory && matchesSubCategory && matchesMaterial && matchesColor && matchesPrice;
    });

    const items = [
        {
            key: "sub",
            label: language === "en" ? "Product Category" : "فئة المنتج",
            children: categories.map((category, index) => ({
                key: `sub${index + 1}`,
                label: (
                    <Checkbox
                        onChange={() => handleCategoryChange(category.id)}
                        checked={selectedCategories.has(category.id)}
                    >
                        {language === "en" ? category.name_en : category.name_ar}
                    </Checkbox>
                ),
                children: subcategories
                    .filter(subcategory => selectedCategories.has(subcategory.cat_id))
                    .map(subcategory => ({
                        key: `sub${category.id}-${subcategory.id}`,
                        label: (
                            <Checkbox
                                onChange={() => handleSubCategoryChange(subcategory.id)}
                                checked={selectedSubCategories.has(subcategory.id)}
                            >
                                {language === "en" ? subcategory.name_en : subcategory.name_ar}
                            </Checkbox>
                        ),
                    })) || [],
            })),
        },
        {
            key: "3",
            label: <hr />,
        },
        {
            key: "sub6",
            label: language === "en" ? "Materials" : "المواد",
            children: [...new Set(products.map(product => product.material && product.material.name_en).filter(Boolean))].map((material, index) => ({
                key: `sub${index + 1}`,
                label: <Checkbox onChange={() => handleMaterialChange(material)}>{language === "en" ? material : material}</Checkbox>,
            })),
        },
        {
            key: "4",
            label: <hr />,
        },
        {
            key: "sub7",
            label: language === "en" ? "Color" : "الألوان",
            children: [...new Set(products.flatMap(product => product.colors).map(color => color.color))].map((color, index) => ({
                key: `sub${index + 1}`,
                label: <Checkbox onChange={() => handleColorChange(color)}><div className="px-10 py-2 mt-2 rounded-lg" style={{ backgroundColor: color }}></div></Checkbox>,
            })),
        },
        {
            key: "5",
            label: <hr />,
        },
        {
            key: "sub8",
            label: language === "en" ? "Price" : "السعر",
            children: [
                {
                    key: "10",
                    label: <div className='flex items-center'>
                        <p className="text-lg" style={{ fontFamily: "Helvetica Neue" }}>{language === "en" ? "From:" : "من:"}</p>
                        <Input type='number' placeholder='0.00' suffix={"$"} className='w-48 mx-2 border-[#BEB093] rounded-lg hover-none active-none' onChange={(e) => handlePriceChange(e, 'min')} />
                        <p className="text-lg" style={{ fontFamily: "Helvetica Neue" }}>{language === "en" ? "To:" : "الي:"}</p>
                        <Input type='number' placeholder='0.00' suffix={"$"} className='w-48 mx-2 border-[#BEB093] rounded-lg hover-none active-none' onChange={(e) => handlePriceChange(e, 'max')} />
                    </div>,
                },
            ],
        },
        {
            key: "6",
            label: <hr />,
        },
        {
            key: "sub9",
            label: language === "en" ? "Other" : "أخري",
            children: [],
        },
    ];

    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const customExpandIcon = ({ isOpen }) =>
        isOpen ? (
            <MinusOutlined className="plus-icon" />
        ) : (
            <PlusOutlined className="plus-icon" />
        );

    const isFav = (id) => {
        return favorites.some(item => item.id === id);
    };

    useEffect(() => {
        if (productStatus === 'idle') {
            dispatch(fetchProducts());
        }
    }, [productStatus, dispatch]);

    return (
        <div dir={language === 'en' ? 'ltr' : 'rtl'}>
            <Navbar2 />
            <div className={`grid ${collapsed ? 'grid-cols-6' : 'grid-cols-4'} max-lg:grid-cols-1 gap-2 ProductList ${language === "ar" && "ProductList_ar"}`}>
                <div className={`h-full ml-2 ${collapsed ? 'mr-3 max-lg:mr-5' : 'mr-6 max-lg:mr-5'} relative`}>
                    <div className="flex items-center justify-between">
                        <div className="flex items-center pl-4">
                            <img src={adjust} className={`items-center ${collapsed ? 'w-5' : 'w-6'} max-lg:w-5 inline-flex`} />
                            <span className={`hide items-center ${language === "en" ? "ml-2" : "mr-2"} ${collapsed ? 'text-lg max-lg:text-xl' : 'text-xl max-lg:text-xl'}`}>
                                {collapsed ? (language === "en" ? 'Show' : 'اظهر') : (language === "en" ? 'Hide' : 'اخفاء')} {t('filters')}
                            </span>
                        </div>
                        <Switch
                            defaultChecked
                            onChange={() => toggleCollapsed()}
                            className="bg-black"
                        />
                    </div>
                    <Menu
                        defaultSelectedKeys={["0"]}
                        defaultOpenKeys={["sub"]}
                        mode="inline"
                        inlineCollapsed={collapsed}
                        items={items}
                        expandIcon={customExpandIcon}
                        className={`${collapsed ? 'hidden' : ''}`}
                    />
                    {collapsed ? null : <img className="line-side absolute max-lg:hidden" src={lineside} />}
                </div>
                <div className={` ${collapsed ? 'lg:col-span-6 lg:px-24 lg:mt-0 max-lg:mt-3 lg:py-0 py-5' : 'lg:col-span-3 max-lg:mt-3 py-5'} bg-[#F7F0E04F]`}>
                    {productStatus === 'loading' ?
                        <div className="text-center my-44"><Spin size="large" /></div> :
                        productStatus === 'succeeded' ?
                            <div className={`grid ${collapsed ? 'grid-cols-4' : 'grid-cols-4'} max-lg:grid-cols-1 gap-4 lg:p-6 max-lg:w-2/3 max-lg:mx-auto`}>
                                {filteredProducts.map((product) => (
                                    <ProductLatest
                                        key={product.id}
                                        id={product.id}
                                        image={`${process.env.REACT_APP_BASE_URL}${product.image}`}
                                        name={language === "en" ? product.name_en : product.name_ar}
                                        desc={language === "en" ? product.desc_en : product.desc_ar}
                                        link={product.id}
                                        price={product.price}
                                        sizes={product.sizes.map(size => size.size[0].size_name)}
                                        active_color={product.color}
                                        colors={product.colors.map(color => color.color)}
                                        offer={product.discountvalue > 0}
                                        discount={product.discountvalue}
                                        rate={product.rate}
                                        fav={isFav(product.id)}
                                    />
                                ))}
                            </div> : productStatus === 'failed' ? <div>{error}</div> : null
                    }
                </div>
            </div>
        </div>
    );
};

export default ProductList;
