import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PrivateAxios } from '../api';

export const rateProduct = createAsyncThunk(
  'rating/rateProduct',
  async ({ item_id, rating, comment }, { rejectWithValue }) => {
    try {
      const response = await PrivateAxios.post('/api/rate_item', {
        item_id,
        rates: rating,
        coment: comment,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const ratingSlice = createSlice({
  name: 'rating',
  initialState: {
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(rateProduct.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(rateProduct.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(rateProduct.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default ratingSlice.reducer;