import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "react-phone-input-2/lib/style.css";
import "../styles/Checkout.css";
import "../styles/Credit.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

const Credit = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const profile = useSelector((state) => state.profile.profile.data);
  const cart = localStorage.getItem('cart');
  
  const cartProducts = JSON.parse(cart).map(product => ({
    code: product.code,
    count: product.count,
    color: product.color,
    size: product.size,
  }));

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      profile,
      cartProducts,
      pay: 'cash'
    };
    console.log(profile)
    // dispatch(handlePay(data))
  }

  return (
    <div className="bg-[#F3F9FF] pb-10 relative px-24 max-lg:px-5 checkout">
      <div className="flex items-center pt-10 max-lg:pt-5">
        <ArrowLeftOutlined className="text-[#DE3033] mr-3" />
        <Link to="/checkout" className="shop text-[#DE3033]">
          BACK TO CHECKOUT
        </Link>
      </div>
      <div className="bg-[#F3F9FF] ">
        <form onSubmit={handleSubmit} className="lg:mt-5">
          <h2>Payment Method</h2>
          <p>Cash on Delivery</p>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Credit;