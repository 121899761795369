import React, { useEffect } from 'react';
import logo from "../assets/logo.png";
import "../styles/AboutUs.css";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAboutInfo } from '../redux/aboutSlice';
import { useTranslation } from "react-i18next";

const AboutComp = () => {

    const { t, i18n } = useTranslation();

    const { language } = i18n;

    const dispatch = useDispatch();
    const aboutInfo = useSelector((state) => state.aboutInfo.aboutInfo);
    const aboutInfoStatus = useSelector((state) => state.aboutInfo.status);
    const error = useSelector((state) => state.aboutInfo.error);

    useEffect(() => {
        if (aboutInfoStatus === 'idle') {
            dispatch(fetchAboutInfo());
        }
    }, [aboutInfoStatus, dispatch]);

    if (aboutInfoStatus === 'failed') {
        return <div>Error: {error}</div>;
    }

    return (
        <div dir={language == "ar" ? 'rtl' : 'ltr'}>
            <div className='px-24 max-lg:px-5 h-auto'>
                <div className='mb-12 mt-5 flex justify-center'>
                    <div><img src={logo} className={` w-2/5 absolute z-10  mt-14 max-lg:mt-9 ${language == "ar" ? 'right-12 max-lg:right-10' : 'left-12 max-lg:left-10'} `} /></div>
                    <div className='bg mx-28 max-lg:mx-0'>
                        <div className='rect relative'>
                            <div className='content'>
                                <p className='text-justify pt-40 max-lg:pt-20 pb-10  px-20 max-lg:px-5 text-3xl max-lg:text-lg'>
                                    {language == "en" ? aboutInfo.title_en : aboutInfo.title_ar}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AboutComp
