import React, { useEffect } from "react";
import line from "../assets/line.png";
import { createFromIconfontCN } from "@ant-design/icons";
import ProductLatest from "./ProductLatest";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { fetchBestItems } from "../redux/productSlice";
import ProductBest3 from "./ProductBest3";

const BestSellers = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const dispatch = useDispatch();
  const bestItems = useSelector((state) => state.products.bestItems || []);
  const productStatus = useSelector((state) => state.products.status);
  const error = useSelector((state) => state.products.error);
  const favorites = useSelector((state) => state.products.favorites);

  const isFav = (id) => {
    return favorites.some((item) => item.id === id);
  };

  useEffect(() => {
    if (productStatus === "idle") {
      dispatch(fetchBestItems());
    }
  }, [productStatus, dispatch]);

  const IconFont = createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js",
  });

  const topTwoBestItems = bestItems.slice(0, 2);

  return (
    <div className="relative px-24 max-lg:px-5">
      <div className="content bg-white grid grid-cols-3 max-xl:grid-cols-1 gap-8 mt-20 mb-14 max-lg:mb-8">
        <div className="flex w-full flex-col">
          <div>
            <div className="flex items-center">
              <img
                src={line}
                className={`absolute top-26 ${
                  language === "en"
                    ? "left-8 max-lg:left-5"
                    : "right-8 max-lg:right-5"
                }`}
                width={50}
              />
              <h4
                className="text-5xl max-lg:text-3xl max-lg:ml-14 text-[#747474]"
                style={{
                  fontFamily: "none",
                }}
              >
                {t("BEST_SELLERS")}
              </h4>
            </div>

            <p
              className="text-gray-700 text-xl"
              style={{
                fontWeight: "400",
                fontFamily: "Comfortaa",
                color: "#0000005E",
              }}
            >
              {t("The_Best_productions")}
            </p>

            <p
              className="text-base mt-6"
              style={{
                fontWeight: "400",
                fontSize: "16px ",
                fontFamily: "Comfortaa",
                color: "#00000096",
                textWrap: "wrap",
              }}
            >
              {t("Discover_best")}
            </p>
          </div>
        </div>

        {productStatus === "loading" ? (
          <div className="text-center my-44">
            <Spin size="large" />
          </div>
        ) : productStatus === "succeeded" && bestItems.length > 0 ? (
          topTwoBestItems.map((product) => {
            if (!product || !product.item) {
              return null; // Skip rendering if product or item is null/undefined
            }

            const { item } = product;

            return (
              <ProductBest3
                key={item.id}
                id={item.id}
                image={`${process.env.REACT_APP_BASE_URL}${item.image}`}
                name={language === "en" ? item.name_en : item.name_ar}
                desc={language === "en" ? item.desc_en : item.desc_ar}
                material={
                  language === "en"
                    ? item?.material?.name_en
                    : item?.material?.name_ar
                }
                link={item.id}
                price={item.price}
                sizes={item.sizes.map((size) => size.size[0].size_name)}
                active_color={item.color}
                colors={item.colors.map((color) => color.color)}
                offer={item.discountvalue > 0}
                discount={item.discountvalue}
                rate={item.rate}
                fav={isFav(item.id)}
              />
            );
          })
        ) : productStatus === "failed" ? (
          <div>{error}</div>
        ) : null}
      </div>
    </div>
  );
};

export default BestSellers;
