import React from 'react'
import stars from "../../assets/stars.png";
import { useTranslation } from 'react-i18next';
const TitleProfile = ({name}) => {
    const { t, i18n } = useTranslation();

    const { language } = i18n;
  

    return (
        <div className="relative mt-5">
            <img className="absolute left-1/3 max-lg:left-3 -top-5" src={stars} />
            <h3 className="userName text-center text-4xl max-lg:text-3xl"> {t('HI')}, {name} !</h3>
            <img className="absolute right-1/3 max-lg:right-3 top-5" src={stars} />
        </div>
    )
}

export default TitleProfile
