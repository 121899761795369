import React, { useEffect } from "react";
import "../styles/Header.css";
import {
  PhoneFilled,
  MailOutlined,
  InstagramFilled,
  ShoppingCartOutlined,
  HeartFilled,
  LinkedinFilled,
  YoutubeFilled,
  TikTokFilled,
} from "@ant-design/icons";
import { createFromIconfontCN } from "@ant-design/icons";
import { Link, NavLink } from "react-router-dom";
import love from "../assets/love.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchContacts } from "../redux/contactSlice";
import { Spin } from "antd"; // Import Ant Design's Spin component

const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js",
});

const Header = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const cart = useSelector((state) => state.products.cart);
  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.contacts.contacts);
  const contactsStatus = useSelector((state) => state.contacts.status);
  const error = useSelector((state) => state.contacts.error);

  useEffect(() => {
    if (contactsStatus === "idle") {
      dispatch(fetchContacts());
    }
  }, [contactsStatus, dispatch]);

  // if (contactsStatus === "loading") {
  //   return (
  //     <div className="text-center">
  //       <Spin size="large" />
  //     </div>
  //   );
  // }

  // Error state
  if (contactsStatus === "failed") {
    return (
      <div className="text-center">
        <p>An error occurred. {error}</p>
      </div>
    );
  }

  return (
    <div className="navigation head_nav">
      <section>
        <div>
          <div className="flex justify-between px-24 max-lg:px-5">
            <div className="items-center inline-flex mr-6 max-lg:mr-0 flex align-center">
              <Link
                to={`tel:${contacts?.phone?.value}`}
                className={`flex items-center ${
                  language === "ar" && "flex-row-reverse"
                } mr-3 max-lg:mr-1`}
              >
                <PhoneFilled
                  className={`size-3 ${language === "en" ? "mr-2" : "ml-2"}`}
                />
                <span className="header text-xs max-lg:hidden">
                  <span className={language === "ar" ? "ml-1" : "mr-1"}>{t("Contact_Us_")}</span>
                  {contacts?.phone?.value}
                </span>
              </Link>
              <Link
                to={`mailto:${contacts?.email?.value}`}
                className={`flex items-center ${
                  language === "ar" && "flex-row-reverse"
                } mr-3 max-lg:mr-1`}
              >
                <MailOutlined
                  className={`size-3 ${language === "en" ? "mr-2" : "ml-2"}`}
                />
                <span className="header text-xs max-lg:hidden">
                  {contacts?.email?.value}
                </span>
              </Link>
              <Link
                to="/contact"
                className={`flex items-center mr-3 max-lg:mr-1 ${
                  language === "ar" && "flex-row-reverse"
                }`}
              >
                <span
                  className={`material-symbols-outlined text-base ${
                    language === "en" ? "mr-2" : "ml-2"
                  }`}
                >
                  support
                </span>
                <span className="header text-xs max-lg:hidden">
                  {t("support_document")}
                </span>
              </Link>
            </div>

            <div className="items-center inline-flex space-x-4 max-lg:space-x-2 flex align-center">
              <div className="flex align-center items-center">
                <Link
                  to={contacts?.facebook?.value}
                  className="ml-3 max-lg:ml-0"
                >
                  <IconFont
                    type="icon-facebook"
                    className=" text-xs max-lg:text-xs"
                  />
                </Link>
                <Link to={contacts?.insta?.value} className="ml-3 max-lg:ml-2">
                  <InstagramFilled className=" text-sm  max-lg:text-sm" />
                </Link>
                <Link
                  to={contacts?.youtube?.value}
                  className="ml-3 max-lg:ml-2"
                >
                  <YoutubeFilled className=" text-base mt-1  max-lg:text-sm" />
                </Link>
                <Link to={contacts?.linked?.value} className="ml-3 max-lg:ml-2">
                  <LinkedinFilled className=" text-sm  max-lg:text-sm" />
                </Link>
                <Link to={contacts?.tiktok?.value} className="ml-3 max-lg:ml-2">
                  <TikTokFilled className=" text-sm  max-lg:text-sm" />
                </Link>
              </div>

              <Link
                to="/cart"
                className="flex align-center border-l-[1px] border-[#505050] "
              >
                <ShoppingCartOutlined className="size-4  ml-2 " />
                <span className="header text-xs ml-2 ">
                  {" "}
                  {cart.length} {t("items")}
                </span>
              </Link>
              <div className="flex items-center">
                <NavLink to="/favorites" className="header text-white">
                  {({ isActive }) =>
                    isActive ? (
                      <img
                        src={love}
                        width={28}
                        height={28}
                        className="py-4 pr-2"
                      />
                    ) : (
                      <HeartFilled className="size-4 mr-2  " />
                    )
                  }
                </NavLink>

                <NavLink
                  to="/profile"
                  className={({ isActive }) =>
                    `header ${isActive ? "text-[#BC874A]" : "text-white"}`
                  }
                >
                  {({ isActive }) =>
                    isActive ? (
                      <span
                        class="material-symbols-outlined"
                        style={{ fontVariationSettings: "'FILL' 1" }}
                      >
                        person
                      </span>
                    ) : (
                      <span
                        class="material-symbols-outlined"
                        style={{ fontVariationSettings: "'FILL' 1" }}
                      >
                        person
                      </span>
                    )
                  }
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Header;
