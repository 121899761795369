import React, { useEffect } from 'react'
import { fetchPrivacy } from '../redux/privacySlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Privacy = () => {

    const { t, i18n } = useTranslation();

    const { language } = i18n;

    const dispatch = useDispatch();
    const privacy = useSelector((state) => state.privacy.privacy);
    const privacyStatus = useSelector((state) => state.privacy.status);
    const error = useSelector((state) => state.privacy.error);

    useEffect(() => {
        if (privacyStatus === 'idle') {
            dispatch(fetchPrivacy());
        }
    }, [privacyStatus, dispatch]);

    return (
        <div>
            <div className='bg-black h-[80vh] max-lg:h-[50vh] m-auto'>
                <h2 className='text-4xl text-white text-center pt-48 max-lg:pt-20'>{t('VINCI_PRIVACY_POLICY')}</h2>
            </div>

            <div className='mt-8 px-24 max-lg:px-5'>
                <h3 className='text-2xl'>{t('Published')} {privacy.from}</h3>
                <p className='my-5 text-[#747474]'>{privacy.data}</p>
            </div>
        </div>
    )
}

export default Privacy
