import React from 'react';
import Navbar2 from '../components/Navbar2';
import { useTranslation } from 'react-i18next';
import Privacy from '../components/Privacy';

const PrivacyPolicy = () => {

    const { t, i18n } = useTranslation();

    const { language } = i18n;

    return (
        <div dir={language == 'en' ? 'ltr' : 'rtl'}>
            <Navbar2 />
            <Privacy />
        </div>
    )
}

export default PrivacyPolicy
