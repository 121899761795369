import React, { useEffect, useState } from "react";
import line from "../assets/line.png";
import list from "../assets/list.png";
import {
  ShoppingCartOutlined,
  HeartOutlined,
  LineOutlined,
} from "@ant-design/icons";

import { Button, Modal } from 'antd';
import { createFromIconfontCN } from "@ant-design/icons";
import { Rate } from "antd";
import { Link } from "react-router-dom";
import love from "../assets/love.png";
import { useDispatch, useSelector } from "react-redux";
import { addFavorite, removeFavorite, addToCart } from "../redux/productSlice";
import { useTranslation } from 'react-i18next';

const Product = ({ id, image, name, price, rate, offer, discount, fav, link, sizes, colors, active_color }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const { language } = i18n;

  return (
    <>
      <div className="  relative flex flex-col product ">
        <div className=" relative">

          <div className="bg-[#f7f8fa]">
            <img className="product_image" src={image} alt="product image" />
          </div>

          <div
            className="bg-white absolute top-4 right-4 py-2 hover_d_block"
            style={{ border: "1px solid #00000014" }}
          >
            <div>
              <span className="material-symbols-outlined px-2 cursor-pointer" onClick={showModal}>
                visibility
              </span>

              <Modal title="" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
                <div className={`grid grid-cols-2 max-lg:grid-cols-1 gap-0 ${language == "ar" && 'flex flex-col-reverse'}`} dir={language == "en" ? 'ltr' : 'rtl'}>
                  <img className="h-72 w-52 max-lg:w-full max-lg:h-full py-5 max-lg:py-3 product_modal" src={image} alt="product image" />
                  <div className="py-5 max-lg:py-3">
                    <div className="flex   justify-between items-center content-between ">
                      <p
                        className=" tracking-tight text-slate-900  flex items-center  "
                        style={{
                          fontWeight: "700",
                          fontSize: "18px ",
                          fontFamily: "Comfortaa",
                          color: "#000000",
                        }}
                      >
                        {name}
                      </p>
                    </div>

                    <div className="flex  items-center mt-2">
                      {offer === true ? (
                        <p
                          className={`text-lg font-bold text-[#DE3033] line-through ${language == "en" ? "mr-3" : "ml-3"}`}
                          style={{
                            fontWeight: "700",
                            fontFamily: "Comfortaa",
                          }}
                        >
                          {price} {t('EGP')}
                        </p>
                      ) : ""}
                      <p
                        className="text-lg font-bold text-slate-900 vinci-text-primary"
                        style={{
                          fontWeight: "700",
                          fontFamily: "Comfortaa",
                        }}
                      >
                        {price - discount} {t('EGP')}
                      </p>
                      <div>
                      </div>
                    </div>

                    <div className='py-3'>
                      <p className='text-[#898989]'>{t('COLOR')}</p>
                      <div className='bg-white flex justify-between items-center mt-1'>
                        <div className='flex items-center'>
                          {
                            active_color ? <button className={`p-3 rounded-full ${language == "en" ? "mr-3" : "ml-3"} colors active_color`} style={{ backgroundColor: active_color }}></button> : null
                          }
                          {
                            colors.map((color) => (
                              <button className={`p-2 rounded-full ${language == "en" ? "mr-3" : "ml-3"} colors`} style={{ backgroundColor: color }}></button>
                            ))
                          }
                        </div>
                        <div className="text-base text-[#898989]">BLACK</div>
                      </div>
                    </div>

                    <div className='py-3'>
                      <p className='text-[#898989]'>{t('SIZE')}</p>
                      <div className='flex justify-between items-center'>
                        <div className='flex items-center'>
                          {
                            sizes.map((size) => (
                              <div className={`p-2 rounded-full ${language == "en" ? "mr-3" : "ml-3"}`}>{size}</div>
                            ))
                          }
                        </div>
                        <div className="text-base text-[#898989]">SMALL</div>
                      </div>
                    </div>

                    <Rate allowHalf defaultValue={rate} className="custom-rate" />
                    <div className="flex justify-between items-center mt-2">
                      <button className="rounded-none border-0 px-2 shadow-none" onClick={() => dispatch(fav === false ? addFavorite(id) : removeFavorite(id))}>
                        {
                          fav === false ? <HeartOutlined className="text-xl mt-3" /> : <img src={love} width={48} height={48} className="mt-2" />
                        }
                      </button>
                      <button className={`rounded-none border-0 px-2 ${language == "en" ? "mr-2" : "ml-2"} shadow-none`} onClick={() => dispatch(addToCart(id))}>
                        <ShoppingCartOutlined className="material-symbols-outlined text-2xl" />
                      </button>
                      <Link to={`/products/${link}`} className="bg-black text-white px-4 py-2 mt-2 w-80 text-center">{t('VIEW_MORE')}</Link>
                    </div>
                  </div>
                </div>
              </Modal>

            </div>
            <div className="py-1 border-y border-[#00000014]">
              <Link to={`/products/${link}`} className="material-symbols-outlined px-2 pt-1" >
                search
              </Link>
            </div>
            <button onClick={() => dispatch(addToCart(id))}>
              <ShoppingCartOutlined className="material-symbols-outlined  px-2" />
            </button>
          </div>
        </div>

        <div className="mt-4   ">
          <div className="flex   justify-between items-center content-between ">
            <p
              className=" tracking-tight text-slate-900  flex items-center  "
              style={{
                fontWeight: "700",
                fontSize: "18px ",
                fontFamily: "Comfortaa",
                color: "#000000",
              }}
            >
              <LineOutlined className={`size-5 ${language == "en" ? 'mr-2' : 'ml-2'}`} />
              {name}
            </p>

            <button onClick={() => dispatch(fav === false ? addFavorite(id) : removeFavorite(id))}>
              {
                fav === false ? <HeartOutlined className="text-xl " /> : <img src={love} width={20} height={20} />
              }
            </button>

          </div>

          <div className="flex  justify-between items-center mt-2">
            <div className="flex items-center">
              {offer === true ? (
                <p
                  className="ml-3 text-xs font-bold line-through text-[#DE3033]"
                  style={{
                    fontWeight: "700",
                    fontFamily: "Comfortaa",
                  }}
                >
                  {price} {t('EGP')}
                </p>
              ) : ""}
              <p
                className={`${offer === true ? 'ml-1' : 'ml-5'} text-base font-bold text-slate-900 vinci-text-primary`}
                style={{
                  fontWeight: "700",
                  fontFamily: "Comfortaa",
                }}
              >
                {price - discount} {t('EGP')}
              </p>
            </div>
            <div>
              <Rate allowHalf defaultValue={rate} className="custom-rate" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
