import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, message, Select } from "antd";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import ProfileInputs from "./ProfileInputs";
import { useDispatch, useSelector } from "react-redux";
import { fetchCountries } from "../redux/countrySlice";
import { fetchCities } from "../redux/citySlice";
import { CaretDownOutlined } from "@ant-design/icons";
import PassInput from "./PassInput";
import { registerUser } from "../redux/authSlice";
import "../styles/signUp.css";
import { Link, Navigate } from "react-router-dom";

const SignUpForm = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const [phone, setPhone] = useState("");
  const [focused, setFocused] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");

  const { isLoading, error, user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const countries = useSelector((state) => state.countries.countries);
  const countriesStatus = useSelector((state) => state.countries.status);
  const errorCountries = useSelector((state) => state.countries.error);

  const cities = useSelector((state) => state.cities.cities);
  const citiesStatus = useSelector((state) => state.cities.status);
  const errorCities = useSelector((state) => state.cities.error);

  useEffect(() => {
    if (countriesStatus === "idle") {
      dispatch(fetchCountries());
    }
  }, [countriesStatus, dispatch]);

  useEffect(() => {
    if (selectedCountry) {
      dispatch(fetchCities(selectedCountry));
    }
  }, [selectedCountry, dispatch]);

  const handleCountrySelect = (value) => {
    setSelectedCountry(value);
    setSelectedCity(null);
  };

  const handleCitySelect = (value) => {
    setSelectedCity(value);
  };

  const handleSubmit = async () => {
    const userData = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      phone: phone,
      country_id: selectedCountry,
      city_id: selectedCity,
      address: address,
      password: password,
    };
    try {
      await dispatch(registerUser(userData)).unwrap();
      message.success(t("signed up"));
    } catch (error) {
      message.error(t("Failed to sign up"));
    }
  };

  return (
    <div
      className={`profileStyle ${
        language === "en"
          ? "lg:rounded-l-full profileStyle"
          : "lg:rounded-r-full profileStyle-ar"
      } `}
    >
      <div className="px-14 max-lg:px-5 py-7">
        <div className="relative mt-5">
          <h3 className="userName text-center text-4xl max-lg:text-2xl">
            {t("SIGN UP")}
          </h3>
        </div>
        <div className="py-14 max-lg:py-0">
          <div className="profileContainer">
            <div
              className={`w-3/5 max-lg:w-full mx-auto  ${
                language === "en" ? "lg:pr-5" : "lg:pl-5"
              }`}
            >
              <Form onFinish={handleSubmit} autoComplete="off" className="my-9">
                <div className="grid grid-cols-2 max-lg:grid-cols-1 gap-6 max-lg:gap-0">
                  <ProfileInputs
                    label={t("First name")}
                    value={firstname}
                    onChange={setFirstName}
                    name="firstName"
                  />
                  <ProfileInputs
                    label={t("Last name")}
                    value={lastname}
                    onChange={setLastName}
                    name="lastName"
                  />
                </div>
                <div>
                  <ProfileInputs
                    label={t("Email address")}
                    value={email}
                    onChange={setEmail}
                    name="email"
                  />
                </div>
                {/* <div>
                  <Form.Item
                    className={`${language === "en" ? 'floating-label-input' : 'floating-label-input-ar'} ${language === "en" ? 'react-tel-input selected-flag ' : 'react-tel-input-ar selected-flag '} focused phone_number`}
                    label={t('Phone number')}
                  >
                    <PhoneInput
                      country={"eg"}
                      value={phone}
                      onChange={(phone) => setPhone(phone)}
                      disableCountryCode={false}
                      onlyCountries={["eg", "us", "gb"]}
                      preferredCountries={["eg"]}
                      placeholder={t('Enter phone number')}
                      onFocus={() => setFocused(true)}
                      onBlur={() => setFocused(false)}
                      className={`bg-white p- ${language === "en" ? 'floating-label-input' : 'floating-label-input-ar'} ${language === "en" ? 'profileStyle react-tel-input form-control' : 'profileStyle-ar react-tel-input form-control'} ${language === "en" ? 'react-tel-input selected-flag ' : 'react-tel-input-ar selected-flag '} rounded placeholder:text-black placeholder:text-xl placeholder:font-normal placeholder:font-Arimo`}
                    />
                  </Form.Item>
                </div> */}

                <div className="relative -top-3">
                  <label
                    className={`${
                      language === "en"
                        ? "floating-label-input"
                        : "floating-label-input floating-label-input-ar"
                    } focused font-semibold relative top-2 right-2`}
                  >
                    {t("Phone number")}
                  </label>

                  <PhoneInput
                    defaultCountry="eg"
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    className={`bg-white p-2 rounded placeholder:text-black placeholder:text-xl placeholder:font-normal placeholder:font-Arimo ${
                      language === "en" ? "en_phone" : "ar_phone"
                    }`}
                  />
                </div>

                <div className="mt-5"></div>

                <div className="grid grid-cols-2 max-lg:grid-cols-1 gap-6 mb-6">
                  <Select
                    value={selectedCountry}
                    onChange={handleCountrySelect}
                    className="rounded placeholder:text-black placeholder:text-xl placeholder:font-normal placeholder:font-Arimo"
                    placeholder={t("Country")}
                    options={countries.map((country) => ({
                      label: language == "en" ? country.name_en : country.name,
                      value: country.id,
                    }))}
                    suffixIcon={<CaretDownOutlined />}
                  />
                  <Select
                    value={selectedCity}
                    onChange={handleCitySelect}
                    className="rounded placeholder:text-black placeholder:text-xl placeholder:font-normal placeholder:font-Arimo"
                    placeholder={t("City")}
                    options={cities.map((city) => ({
                      label: language == "en" ? city.name_en : city.name,
                      value: city.id,
                    }))}
                    suffixIcon={<CaretDownOutlined />}
                  />
                </div>

                <div>
                  <ProfileInputs
                    label={t("Address")}
                    value={address}
                    onChange={setAddress}
                    name="address"
                  />
                </div>
                <div>
                  <PassInput
                    placeholder={t("Password")}
                    value={password}
                    onChange={setPassword}
                    name="password"
                  />
                </div>
                <Link to="/login" className="mb-3">
                  {t("haveLogin")}
                </Link>
                <p className="text-center my-5">
                  {error && (
                    <p className="text-red-500 mt-4">
                      {"Sign up failed. Please try again."}
                    </p>
                  )}
                </p>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="border-0 py-5 px-14 mt-5 rounded-lg flex mx-auto max-lg:w-full save_btn"
                >
                  {t("SIGN")}
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
