import React, { useEffect, useState } from "react";
import { Tabs, Alert, Spin, Empty } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import "../../styles/Orders.css";
import OrderItem from "./OrderItem";
import { useTranslation } from "react-i18next";
import { fetchOrders } from "../../redux/orderSlice";
import { useDispatch, useSelector } from "react-redux";

const onChange = (key) => {
  console.log(key);
};

const Orders = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders.orders);
  const ordersStatus = useSelector((state) => state.orders.status);
  const error = useSelector((state) => state.orders.error);

  useEffect(() => {
    if (ordersStatus === "idle") {
      dispatch(fetchOrders());
    }
  }, [ordersStatus, dispatch]);

  const [tabPosition, setTabPosition] = useState("left");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1280) {
        setTabPosition("top");
      } else {
        setTabPosition("left");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let content;

  if (ordersStatus === "loading") {
    content = <Spin size="large" />;
  } else if (ordersStatus === "succeeded") {
    if (orders && orders.length > 0) {
      const items = orders.map((order, index) => ({
        key: String(index + 1),
        label: (
          <div className="headingps">
            <h3 className="text-base font-semibold text-start">
              {t("order")} {order.code}
            </h3>
            <div className="headingps flex justify-between">
              <h3>{order.items.length} {t("items")}</h3>
              <h3>{order.total} {t("EGP")}</h3>
            </div>
          </div>
        ),
        children: <OrderItem order={order} />,
      }));

      content = (
        <Tabs
          defaultActiveKey="1"
          className="custome_tap"
          items={items}
          onChange={onChange}
          tabPosition={tabPosition}
        />
      );
    } else {
      content = <Empty description={t("No orders found")} className="mt-24 max-lg:mt-5"/>;
    }
  } else if (ordersStatus === "failed") {
    content = <Empty description={error} className="mt-24 max-lg:mt-5"/>;
  }

  return (
    <div className="lg:min-h-[520px]">
      <div className="flex items-center mx-4">
        <h3 className={`text-xl ${language === "en" ? "mr-3" : "ml-3"}`}>
          {t("CURRENT ORDERS")}
        </h3>
        <div>
          <CaretDownOutlined className="text-lg mt-1" />
        </div>
      </div>
      <div className="">{content}</div>
    </div>
  );
};

export default Orders;