import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { publicAxios } from '../api';

const initialState = {
  tax: [],
  status: 'idle',
  error: null,
};

export const fetchTax = createAsyncThunk('tax/fetchTax', async () => {
  const response = await publicAxios.get('/api/get_tax');
  return response.data.data;
});

const aboutSlice = createSlice({
  name: 'tax',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTax.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTax.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.tax = action.payload;
      })
      .addCase(fetchTax.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default aboutSlice.reducer;