import React from "react";
import "../styles/Header.css";
import girl from "../assets/girl.png";
import rec from "../assets/rec.png";
import diamond from "../assets/diamond.png";
import lifebuoy from "../assets/lifebuoy.png";
import shipping from "../assets/shipping.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/authSlice";
import AnimatedComponent from "./AnimatedComponent";

const Main = () => {
  const { t, i18n } = useTranslation();

  const { language } = i18n;

  const { user, remember_token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  console.log(remember_token);
  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="" dir={language == "ar" && "ltr"}>
      <div className=" bg-white grid grid-cols-2 max-lg:grid-cols-2 gap-0 flex h-screen max-lg:h-[450px]">
        {/* <div className='max-lg:hidden'>
                    <img src={girl} className="w-3/5 h-[700px] pl-0 absolute top-8 z-10	" />
                </div> */}

        <div>
          <img
            src={girl}
            className="w-2/3 max-lg:w-3/5 h-[700px] pl-0 absolute top-8 max-lg:top-10 z-10"
          />
        </div>

        <div className="mt-14 max-lg:mt-0 lg:relative">
          <img
            src={rec}
            className="absolute z-0 right-48 max-lg:right-3 max-lg:top-28"
            style={{ height: "400px" }}
          />

          <div className="z-10 relative max-lg:mr-6 mt-32 max-lg:mt-32 lg:w-5/6 lg:ml-7">
            <AnimatedComponent animationClass="animation_right">
              <h2
                className="text-5xl max-lg:text-2xl flex justify-center max-lg:justify-end"
                style={{
                  fontWeight: "400",
                  textAlign: "left",
                  fontFamily: "Chilanka,cursive",
                }}
              >
                {t("spring_special")}
              </h2>
              <h2
                className="text-5xl max-lg:text-2xl flex justify-center max-lg:justify-end max-lg:mr-10"
                style={{
                  fontWeight: "400",
                  textAlign: "center",
                  alignContent: "center",
                  fontFamily: "Chilanka,cursive",
                }}
              >
                {t("order_main")}
              </h2>
            </AnimatedComponent>
            <AnimatedComponent animationClass="slide-in-bottom">
              <Link to="/products">
                <button className="bg-black text-white button-animation font-bold py-4 p-8 max-lg:p-5 max-lg:py-3 items-center mt-5 max-lg:mt-6 max-lg:mr-1 flex justify-center max-lg:justify-end text-center lg:mx-auto max-lg:ml-auto">
                  {t("SHOP_NOW")}
                </button>
              </Link>
            </AnimatedComponent>
            {/* <h2>Welcome, {user?.user?.email}</h2>
                        <button onClick={handleLogout}>Logout</button> */}
          </div>
        </div>
      </div>
      <div
        className="grid grid-cols-3 max-lg:grid-cols-1 gap-0 relative z-20"
        dir={language == "en" ? "ltr" : "rtl"}
      >
        <div className=" flex justify-content px-14  flex items-center bg-[#F7F0E0]">
          <div className="circle items-center max-lg:w-32 max-lg:h-20">
            <div className="items-center ">
              <img src={diamond} className="w-35 h-15 " />
            </div>
          </div>

          <div className="p-4 flex flex-col align-center">
            <div className="items-center">
              <div
                className="text-xl mb-2 text-[#453722]  items-center"
                style={{
                  fontWeight: "700",
                  fontSize: "18px ",
                  fontFamily: "Comfortaa",
                }}
              >
                {t("Safe_Payment")}
              </div>
              <p
                className="text-[#00000096] "
                style={{
                  fontWeight: "400",
                  fontSize: "16px ",
                  fontFamily: "Comfortaa",
                }}
              >
                {t("Secure_transactions")}
              </p>
            </div>
          </div>
        </div>
        <div className=" flex justify-content px-14  flex items-center bg-[#F2EBDB]">
          <div className="circle items-center Rotate-animation">
            <div>
              <img src={lifebuoy} className="lg:w-35 h-15  items-center " />
            </div>
          </div>

          <div className="  p-4 flex flex-col  items-center">
            <div>
              <div
                className=" text-xl mb-2 text-[#453722] "
                style={{
                  fontWeight: "700",
                  fontSize: "18px ",
                  fontFamily: "Comfortaa",
                }}
              >
                {t("support_24")}
              </div>
              <p
                className="text-[#00000096] "
                style={{
                  fontWeight: "400",
                  fontSize: "16px ",
                  fontFamily: "Comfortaa",
                }}
              >
                {t("Always")}
              </p>
            </div>
          </div>
        </div>

        <div className=" flex justify-content px-14  flex items-center bg-[#F7F0E0]">
          <div className="circle items-center relative w-24 h-24 max-lg:w-24 max-lg:h-20">
            <div className="shipping-animation">
              <img src={shipping} className="lg:w-20 h-15  items-center" />
            </div>
          </div>

          <div className="   p-4 flex flex-col  items-center">
            <div>
              <div
                className=" text-xl mb-2 text-[#453722] "
                style={{
                  fontWeight: "700",
                  fontSize: "18px ",
                  fontFamily: "Comfortaa",
                }}
              >
                {t("Free_shipping")}
              </div>
              <p
                className="text-[#00000096] "
                style={{
                  fontWeight: "400",
                  fontSize: "16px ",
                  fontFamily: "Comfortaa",
                }}
              >
                {t("No_cost")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
