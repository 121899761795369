import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import App from './App';
import './i18n';
import { createRoot } from 'react-dom/client';
import { Spin } from 'antd';

// ReactDOM.render(
//   <Suspense fallback={<div>Loading...</div>}>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </Suspense>
//   ,

//   document.getElementById('root')
// );

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Suspense fallback={<div className="text-center my-56"><Spin size="large" /></div>}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

