import React, { useState } from "react";
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const ProfileInputs = ({ label, value, onChange }) => {
    const { t, i18n } = useTranslation();
    const { language } = i18n;
    const [focused, setFocused] = useState(false);

    return (
        <Form.Item
            className={`relative ${language === "en" ? 'floating-label-input' : 'floating-label-input-ar'} ${focused || value ? 'focused' : ''}`}
            label={focused || value ? label : ''}
        >
            <Input
                placeholder={label}
                value={value}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onChange={(e) => onChange(e.target.value)}
                className={`bg-white ${language === "en" ? 'floating-label-input' : 'floating-label-input-ar'} w-full p-3 font-semibold border-none shadow-none rounded placeholder:text-black placeholder:text-xl placeholder:font-normal placeholder:font-Arimo`}
            />
        </Form.Item>
    );
};

export default ProfileInputs;