import React from 'react'
import { useTranslation } from 'react-i18next';

const Statistics = () => {

    const { t, i18n } = useTranslation();

    const { language } = i18n;

    return (
        <div className='px-24 max-lg:px-5'>
            <div className="grid grid-cols-4 max-lg:grid-cols-1 gap-4 my-12 items-center text-center">
                <div className=" p-4 flex flex-col items-center  ">
                    <div className="mb-8">
                        <div
                            className=" text-2xl mb-2 text-[#D4AA73] items-center "
                            style={{
                                fontWeight: "600",
                                fontFamily: "Comfortaa",
                                textAlign: "center",
                            }}
                        >
                            264
                        </div>
                        <p
                            className="text-xl text-[#000000] "
                            style={{
                                fontWeight: "600",
                                fontFamily: "Comfortaa",
                            }}
                        >
                             {t('Happy_Customes')} 
                        </p> 
                    </div>
                </div>

                <div className={`p-4 flex flex-col items-center ${language == "en" ? 'border-l-2 max-lg:border-l-0': 'border-r-2 max-lg:border-r-0'} border-[#D4AA7366]`}
                >
                    <div className="mb-8  items-center">
                        <div
                            className=" text-2xl mb-2 text-[#D4AA73] "
                            style={{
                                fontWeight: "600",
                                fontFamily: "Comfortaa",
                                textAlign: "center"
                            }}
                        >
                            50+
                        </div>
                        <p
                            className="text-xl text-[#000000] items-center"
                            style={{
                                fontWeight: "600",
                                fontFamily: "Comfortaa",
                                marginLeft: "24px"
                            }}
                        >
                             {t('Different_Demo_Sites')} 
                        </p>
                    </div>
                </div>

                <div
                    className={`p-4 flex flex-col ${language == "en" ? 'border-l-2 max-lg:border-l-0': 'border-r-2 max-lg:border-r-0'} border-[#D4AA7366]`}
                >
                    <div className="mb-8">
                        <div
                            className=" text-2xl mb-2 text-[#D4AA73] "
                            style={{
                                fontWeight: "600",
                                fontFamily: "Comfortaa",
                                textAlign: "center"
                            }}
                        >
                            88k
                        </div>
                        <p
                            className="text-xl text-[#000000] "
                            style={{
                                fontWeight: "600",
                                fontFamily: "Comfortaa",
                                marginLeft: "24px"

                            }}
                        >
                             {t('Line_Code')} 
                        </p>
                    </div>
                </div>

                <div
                    className={`p-4 flex flex-col ${language == "en" ? 'border-l-2 max-lg:border-l-0': 'border-r-2 max-lg:border-r-0'} border-[#D4AA7366]`}
                >
                    <div className="mb-8">
                        <div
                            className=" text-2xl mb-2 text-[#D4AA73] "
                            style={{
                                fontWeight: "600",
                                fontFamily: "Comfortaa",
                                textAlign: "center"
                            }}
                        >
                            264
                        </div>
                        <p
                            className="text-xl text-[#000000] text-center"
                            style={{
                                fontWeight: "600",
                                fontFamily: "Comfortaa",
                            }}
                        >
                             {t('Shopping')} 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Statistics
