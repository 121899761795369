import React from 'react'
import Navbar2 from '../components/Navbar2';
import SaleProducts from '../components/SaleProducts';
import { useTranslation } from 'react-i18next';

const Sale = () => {

  const { t, i18n } = useTranslation();

  const { language } = i18n;

  return (
    <div dir={language == 'en' ? 'ltr' : 'rtl'}>
      <Navbar2 />
      <SaleProducts />
    </div>
  )
}

export default Sale
