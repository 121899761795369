import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'antd';
import ProfileInputs from "./ProfileInputs";
import PassInput from "./PassInput";
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../redux/authSlice';
import stars from "../assets/stars.png";
import { Link } from "react-router-dom";

const LoginForm = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const { isLoading, error, user } = useSelector((state) => state.auth);

  const handleSubmit = () => {
    const userData = {
      email: email,
      // phone: phone,
      password: password,
    };
    dispatch(loginUser(userData));
  };


  return (
    <>
      <div className={`profileStyle ${language === "en" ? 'lg:rounded-l-full profileStyle' : 'lg:rounded-r-full profileStyle-ar'} `}>
        <div className="px-14 max-lg:px-5 py-7">
          <div className="relative mt-5">
            <img className="absolute left-1/3 max-lg:left-3 -top-5" src={stars} />
            <h3 className="userName text-center text-4xl max-lg:text-2xl">{t("LOGIN")}</h3>
            <img className="absolute right-1/3 max-lg:right-3 top-5" src={stars} />
          </div>
          <div className="py-20 max-lg:py-5">
            <div className='profileContainer'>
              <div className={`w-3/5 max-lg:w-full mx-auto  ${language === "en" ? 'lg:pr-14' : 'lg:pl-14'}`}>
                <Form autoComplete="off" onFinish={handleSubmit} className="my-9">
                  <div>
                    <ProfileInputs label={t('Email address')} value={email} onChange={setEmail} name="email" />
                  </div>
                  <div>
                    <PassInput placeholder={t('Password')} value={password} onChange={setPassword} name="password" />
                  </div>
                  <div className="flex items-center justify-between mb-5 max-lg:text-xs">
                  <p> {t('haveaccount')}<Link to="/signup" className="underline">{t('Register')}</Link></p>
                  <Link to="/forgotpassword">{t('Forgot password')}</Link>
                  </div>
                  <p className="text-center my-5">
                    {error && <p className="text-red-500 mt-4">{'Login failed. Please try again.'}</p>}</p>
                  <Button htmlType="submit" className="border-0 py-5 px-10 rounded-lg flex mx-auto max-lg:w-full save_btn" disabled={isLoading}>
                    {t('LOGIN')}
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
