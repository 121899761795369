import React, { useEffect, useState } from 'react';
import { Empty, Radio, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import Navbar2 from '../components/Navbar2';
import sweet2 from "../assets/sweet2.png";
import sweet3 from "../assets/sweet3.png";
import sweetshirt from "../assets/sweetshirt.png";
import love from "../assets/love.png";
import { Tabs } from "antd";
import { HeartOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import "../styles/ProductDetails.css";
import Related from '../components/Related';
import { useDispatch, useSelector } from 'react-redux';
import { addToCartWithCount, addFavorite, removeFavorite, fetchProducts, fetchRealted_items } from '../redux/productSlice';
import { useTranslation } from 'react-i18next';
import ProductLatest from '../components/ProductLatest';

const ProductDetails = () => {
    const { t, i18n } = useTranslation();
    const { language } = i18n;
    const onChange = (e) => setSize(e.target.value);
    const [tabPosition, setTabPosition] = useState("left");
    const [activeColorIndex, setActiveColorIndex] = useState(-1);
    const changeTabPosition = (e) => setTabPosition(e.target.left);
    const dispatch = useDispatch();
    const products = useSelector((state) => state.products.products);
    const productStatus = useSelector((state) => state.products.status);
    const error = useSelector((state) => state.products.error);
    const favorites = useSelector(state => state.products.favorites);
    const { id } = useParams();
    const productId = parseInt(id);
    const product = products.find(p => p.id === parseInt(id));
    const [count, setCount] = useState(1);
    const [color, setColor] = useState(product?.color || null);
    const [size, setSize] = useState("S");
    const [mainImage, setMainImage] = useState(product ? product.image : null);

    const realted_items = useSelector(
        (state) => state.products.realted_items || []
    );

    useEffect(() => {
        if (productStatus === 'idle') {
            dispatch(fetchProducts());
        }
    }, [productStatus, dispatch]);

    useEffect(() => {
        dispatch(fetchRealted_items(id));
    }, [dispatch, id]);

    // useEffect(() => {
    //     if (productStatus === 'idle' && id) {
    //         dispatch(fetchRealted_items(id));
    //     }
    // }, [productStatus, dispatch , id]);

    if (!product) {
        return <div>Product not found</div>;
    }

    const handleColorClick = (colorImage, index, colorValue) => {
        setMainImage(colorImage);
        setActiveColorIndex(index);
        setColor(colorValue);
    };

    const isFav = (id) => favorites.some((item) => item.id === id);

    const handleIncrease = () => setCount(prevCount => prevCount + 1);
    const handleDecrease = () => setCount(prevCount => Math.max(prevCount - 1, 1));

    return (
        <div className='ProductDetails' dir={language === 'en' ? 'ltr' : 'rtl'}>
            <Navbar2 />
            <div className='px-24 max-lg:px-5'>
                <div className="grid grid-cols-4 max-lg:grid-cols-1 bg-[#F7F0E094] py-28 max-lg:py-5 max-lg:px-5">
                    <div className={`flex justify-center col-span-2 ${language === "ar" && "mr-14"}`}>
                        <Tabs defaultActiveKey="1" tabPosition={tabPosition}>
                            {mainImage ? <Tabs.TabPane tab={
                                <img src={`${process.env.REACT_APP_BASE_URL}${mainImage}`} className='mx-auto w_taps' />
                            } key="1">
                                <div className='lg:px-5 mx-auto'>
                                    <img src={`${process.env.REACT_APP_BASE_URL}${mainImage}`} className='mx-auto max-lg:my-14 image_details' />
                                </div>
                            </Tabs.TabPane>
                                :
                                <Tabs.TabPane tab={<img src={`${process.env.REACT_APP_BASE_URL}${product.image}`} className='mx-auto w_taps' />} key="1">
                                    <div className='lg:px-5 mx-auto'>
                                        <img src={`${process.env.REACT_APP_BASE_URL}${product.image}`} className='mx-auto max-lg:my-14 image_details' />
                                    </div>
                                </Tabs.TabPane>
                            }
                            {product.images.map((image, index) => (
                                <Tabs.TabPane key={`${index + 2}`} tab={<img src={`${process.env.REACT_APP_BASE_URL}${image.image}`} className='mx-auto w_taps' />}>
                                    <div className='px-5'>
                                        <img src={`${process.env.REACT_APP_BASE_URL}${image.image}`} width="350px" className='mx-auto max-lg:my-14' />
                                    </div>
                                </Tabs.TabPane>
                            ))}
                        </Tabs>
                    </div>
                    <div className='pl-2 max-lg:mt-5'>
                        <div className="justify-between mt-2">
                            <p className="text-xl tracking-tight text-slate-900" style={{ fontWeight: "700", fontSize: "25px ", fontFamily: "Comfortaa", color: "#000000" }}>
                                {language === "en" ? product.name_en : product.name_ar}
                            </p>
                            <div className="flex my-5 justify-between items-center">
                                <div className='flex items-center'>
                                    {product.discountvalue > 0 && (
                                        <p className={`text-base font-bold line-through text-[#DE3033] ${language === "en" ? "mr-3" : "ml-3"}`} style={{ fontWeight: "700", fontFamily: "Comfortaa" }}>
                                            {product.price} {t('EGP')}
                                        </p>
                                    )}
                                    <p className="font-bold text-slate-900" style={{ fontWeight: "700", fontSize: "18px ", fontFamily: "Comfortaa", color: "#BC874A" }}>
                                        {product.price - product.discountvalue} {t('EGP')}
                                    </p>
                                </div>
                                <button onClick={() => dispatch(isFav(product.id) ? removeFavorite(product.id) : addFavorite(product.id))}>
                                    {isFav(product.id) ? <img src={love} width={20} height={20} /> : <HeartOutlined className="text-xl items-center" />}
                                </button>
                            </div>
                        </div>
                        <div className='mb-5'>
                            <p className='text-[#898989]'>{t('COLOR')}</p>
                            <div className='bg-white flex justify-between items-center pl-5 pr-4 py-3 mt-1'>
                                <div className='flex items-center'>
                                    {product.color && (
                                        <button className={`p-2 rounded-full ${language === "en" ? "mr-3" : "ml-3"} colors ${activeColorIndex === -1 ? 'active_color p-3' : ''}`} style={{ backgroundColor: product.color }} onClick={() => handleColorClick(product.image, -1, product.color)}></button>
                                    )}
                                    {product.colors.map((color, index) => (
                                        <button key={index} className={`p-2 rounded-full ${language === "en" ? "mr-3" : "ml-3"} colors ${activeColorIndex === index ? 'active_color p-3' : ''}`} style={{ backgroundColor: color.color }} onClick={() => handleColorClick(color.image, index, color.color)}></button>
                                    ))}
                                </div>
                                <div className="text-base text-[#898989]">{color ? color.toUpperCase() : 'BLACK'}</div>
                            </div>
                        </div>
                        <div className='mb-5'>
                            <p className='text-[#898989]'>{t('SIZE')}</p>
                            <div className='bg-white flex justify-between items-center pl-5 pr-4 py-1 mt-1'>
                                <div className='flex items-center'>
                                    <Radio.Group onChange={onChange} value={size} defaultValue={"S"}>
                                        {product.sizes.map((size, index) => (
                                            size.size.map((nestedSize, nestedIndex) => (
                                                <Radio key={`${index}-${nestedIndex}`} className={`py-2 font-semibold rounded-full`} value={nestedSize.size_name}>{nestedSize.size_name}</Radio>
                                            ))
                                        ))}
                                    </Radio.Group>
                                </div>
                                <div className="text-base text-[#898989]">{size === "S" ? "SMALL" : size === "M" ? "Medium" : size === "L" ? "LARGE" : size === "XL" ? "X-LARGE" : "Large sizes"}</div>
                            </div>
                        </div>
                        <div className='mb-5'>
                            <p className='text-[#898989]'>{t('QTY')}</p>
                            <div className='flex justify-between items-center pl-5 pr-4 py-1 mt-1'>
                                <button onClick={handleDecrease}><MinusOutlined /></button>
                                <div className='text-lg font-semibold'>{count}</div>
                                <button onClick={handleIncrease}><PlusOutlined /></button>
                            </div>
                        </div>
                        <div className='flex justify-center bg-black'>
                            <button className='bg-black text-xl text-white py-2' onClick={() => dispatch(addToCartWithCount({ id: +id, count, size, color }))}>
                                {t('Add_To_Cart')}
                            </button>
                        </div>
                    </div>
                </div>

                <div className="lg:px-10 py-14">
                    <div className="flex head-vision grid grid-cols-3 max-lg:grid-cols-1 gap-10">
                        <div className='lg:mx-auto'>
                            <h5>{t('overview')}</h5>
                            <p>{product.desc_en == null ? <p>There are no description</p> : <p>{language === "en" ? product.desc_en : product.desc_ar}</p>}</p>
                        </div>
                        <div className='lg:mx-auto'>
                            <h5>{t('details')}</h5>
                            <p>{product.overview_en == null ? <p>There are no details</p> : <li>{language === "en" ? product.overview_en : product.overview_ar}</li>}</p>
                        </div>
                        <div className='lg:mx-auto'>
                            <h5>{t('fit')}</h5>
                            <p>{product.fit_en == null ? <p>There are no fit</p> : <li>{language === "en" ? product.fit_en : product.fit_ar}</li>}</p>
                        </div>
                    </div>
                </div>




                {/* ------------------- related ---------------------- */}

                <div className="pb-9">
                    <h5 className="text-2xl">{t("Related")}</h5>
                    <div className="">
                        {productStatus === "loading" ? (
                            <div className="text-center my-44">
                                <Spin size="large" />
                            </div>
                        ) : productStatus === "succeeded" && realted_items.length > 0 ? (
                            <div className={`grid grid-cols-3 max-lg:grid-cols-1 gap-10 mt-9`}>
                                {realted_items.map((item) => (
                                    <ProductLatest
                                        key={item.id}
                                        id={item.id}
                                        image={`${process.env.REACT_APP_BASE_URL}${item.image}`}
                                        name={language === "en" ? item.name_en : item.name_ar}
                                        desc={language === "en" ? item.desc_en : item.desc_ar}
                                        material={
                                            language === "en"
                                                ? item?.material?.name_en
                                                : item?.material?.name_ar
                                        }
                                        link={item.id}
                                        price={item.price}
                                        sizes={item.sizes.map((size) => size.size[0].size_name)}
                                        active_color={item.color}
                                        colors={item.colors.map((color) => color.color)}
                                        offer={item.discountvalue > 0}
                                        discount={item.discountvalue}
                                        rate={item.rate}
                                        fav={isFav(item.id)}
                                    />
                                ))}
                            </div>
                        ) : productStatus === "failed" ? (
                            <div>{error}</div>
                        ) : (
                            <div className="mx-auto flex justify-center text-center col-span-3">
                                <Empty description="No related products" />
                            </div>
                        )}
                    </div>
                </div>

                {/* <Related id={product.id}/> */}
            </div>
        </div>
    );
}

export default ProductDetails;
