import React, { useState } from "react";
import { ArrowLeftOutlined, ArrowRightOutlined, DownOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Dropdown, Space, Input } from "antd";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { removeFromCart, updateCartQuantity } from "../redux/productSlice";
import { useTranslation } from "react-i18next";
import "../styles/Cart.css";

const CartsComp = () => {
    const { t, i18n } = useTranslation();
    const { language } = i18n;
    const cart = useSelector((state) => state.products.cart);
    const dispatch = useDispatch();

    const [quantities, setQuantities] = useState(
        cart.reduce((acc, product) => ({
            ...acc,
            [`${product.id}-${product.color}-${product.size}`]: product.count
        }), {})
    );

    const handleIncrease = (id, color, size) => {
        const key = `${id}-${color}-${size}`;
        const newQuantity = quantities[key] + 1;

        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [key]: newQuantity,
        }));

        dispatch(updateCartQuantity({ id, size, color, count: newQuantity }));
    };

    const handleDecrease = (id, color, size) => {
        const key = `${id}-${color}-${size}`;
        const newQuantity = Math.max(quantities[key] - 1, 1);

        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [key]: newQuantity,
        }));

        dispatch(updateCartQuantity({ id, size, color, count: newQuantity }));
    };
    

    const totalPrice = cart.reduce(
        (total, product) => total + product.price * quantities[`${product.id}-${product.color}-${product.size}`],
        0
    );

    const shippingCost = cart.reduce(
        (total, product) => total + product.shipping_v,
        0
    );

    const totalCost = totalPrice + shippingCost;

    const items = [
        {
            label: <a>{t('STANDARD_DELIVERY')} - {shippingCost}{t('EGP')}</a>,
            key: "0",
        },
    ];

    return (
        <div>
            <div className="grid grid-cols-4 max-lg:grid-cols-1 Cart">
                <div className="col-span-3 py-8 pr-20 max-lg:pr-5 pl-24 max-lg:pl-5 bg-[#D9D9D921] font-semibold">
                    <div>
                        <div className="headingp flex justify-between text-2xl max-lg:text-xl mb-4">
                            <h3>{t('SHOPPING_CART')}</h3>
                            <h3>{cart.length} {t('ITEMS')}</h3>
                        </div>
                        <hr />
                    </div>
                    <div className="headp grid grid-cols-12 gap-4 py-3 text-sm font-semibold">
                        <div className="col-span-5">
                            <p>{t('PRODUCT_DETAILS')}</p>
                        </div>
                        <div className="col-span-3 text-center">
                            <p>{t('PRICE')}</p>
                        </div>
                        <div className="col-span-2 text-center">
                            <p> {t('QTY')}</p>
                        </div>
                        <div className="col-span-2 text-end">
                            <p>{t('TOTAL')}</p>
                        </div>
                    </div>

                    {cart.map((product) => (
                        <div key={product.id}>
                            <div className="headp grid grid-cols-12 max-lg:grid-cols-11 gap-4 max-lg:gap-2 my-6 max-lg:text-sm">
                                <div className="col-span-5">
                                    <div className="max-lg:grid max-lg:grid-cols-3 max-lg:gap-4 max-lg:gap-2 lg:flex items-center">
                                        <div className="max-lg:col-span-2 border border-[#e2e2e2] p-3">
                                            <img
                                                src={`${process.env.REACT_APP_BASE_URL}${product.color == product.colors.map((color , index) => (color.color))  ? product.colors.map((color , index) => (color.image)) : product.image}`}
                                                className="w-1/3 max-lg:w-3/5 cart_image"
                                            />
                                        </div>
                                        <div className="lg:mx-4">
                                            <p>{language === "en" ? product.name_en : product.name_ar}</p>
                                            <button
                                                className="px-0 mt-4 max-lg:mt-1 max-lg:text-xs text-[#DE3033] border-0 bg-transparent shadow-none"
                                                onClick={() => dispatch(removeFromCart({ id: product.id, size: product.size, color: product.color }))}
                                            >
                                                {t('REMOVE')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid col-span-3 max-lg:col-span-2 items-center text-center">
                                    <div>
                                        <p className="inline-flex">{product.price}</p>
                                    </div>
                                </div>
                                <div className="grid col-span-2 items-center">
                                    <div className="flex justify-between items-center py-1">
                                        <button onClick={() => handleDecrease(product.id, product.color, product.size)}>
                                            <MinusOutlined />
                                        </button>
                                        <div className="text-lg font-semibold">{quantities[`${product.id}-${product.color}-${product.size}`]}</div>
                                        <button onClick={() => handleIncrease(product.id, product.color, product.size)}>
                                            <PlusOutlined />
                                        </button>
                                    </div>
                                </div>
                                <div className="grid col-span-2 items-center text-end lg:mr-2">
                                <p>{(quantities[`${product.id}-${product.color}-${product.size}`] * product.price).toFixed(2)}</p>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className="flex items-center">
                        {language === 'en' ? (
                            <ArrowLeftOutlined className="mr-2 text-[#DE3033]" />
                        ) : (
                            <ArrowRightOutlined className="ml-2 text-[#DE3033]" />
                        )}
                        <Link to="/products" className="shop text-[#DE3033]">
                            {t('CONTINUE_SHOPPING')}
                        </Link>
                    </div>
                </div>
                <div className="col-span-1">
                    <div className="table py-8 pr-10 max-lg:pr-5 pl-10 max-lg:pl-5 bg-[#E2E2E2] h-full">
                        <div className="headingp mb-4">
                            <h3 className="text-2xl max-lg:text-xl">{t('ORDER_SUMMARY')}</h3>
                        </div>
                        <hr className="text-[#cfcfcf] border border-[#cfcfcf]" />
                        <div className="headingps flex justify-between py-2">
                            <h3>{cart.length} {t('ITEMS')}</h3>
                            <h3>{totalPrice.toFixed(2)} {t('EGP')}</h3>
                        </div>
                        <div className="headingps my-4">
                            <h3>{t('SHIPPING')}</h3>
                        </div>
                        <div>
                            <Dropdown
                                menu={{ items }}
                                trigger={["click"]}
                                className="bg-[#FFFFFF] p-4 ml-4 text-sm"
                            >
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space>
                                        {t('STANDARD_DELIVERY')} - {shippingCost}{t('EGP')}
                                        <DownOutlined />
                                    </Space>
                                </a>
                            </Dropdown>
                        </div>
                        <div className="headingps my-2 pt-6">
                            <h3>{t('PROMO_CODE')}</h3>
                        </div>
                        <Input
                            className={`bg-[#FFFFFF] py-3 px-4 ml-4 ${language === "ar" ? 'w-[216px]' : 'w-60'}  rounded-none border-white mb-4`}
                            placeholder={t('ENTER_YOUR_CODE')}
                        />
                        <button className="bg-[#DE3033] text-white py-3 px-4 ml-4 w-32 rounded-none mb-7 text-lg">
                            {t('APPLY')}
                        </button>
                        <hr className="text-[#cfcfcf] border border-[#cfcfcf]" />
                        <div className="headingps flex justify-between py-5">
                            <h3>{t('TOTAL_COST')}</h3>
                            <h3>{totalCost.toFixed(2)} {t('EGP')}</h3>
                        </div>
                        <div>
                            <Link to="/checkout">
                                <button className="w-full bg-black text-white py-3 px-4 rounded-none mb-4">
                                    {t('CHECKOUT')}
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartsComp;
