import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { publicAxios } from '../api';

const initialState = {
  terms: [],
  status: 'idle',
  error: null,
};

export const fetchTerms = createAsyncThunk('terms/fetchTerms', async () => {
  const response = await publicAxios.get('/api/get_privacy');
  return response.data.data;
});

const termsSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTerms.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTerms.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.terms = action.payload;
      })
      .addCase(fetchTerms.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default termsSlice.reducer;